import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {CommonModalComponent} from "../../../shared/common-modal/common-modal.component";

@Component({
  selector: 'app-odm-edit-package-offer-modal',
  templateUrl: './odm-edit-package-offer-modal.component.html',
  styleUrls: ['./odm-edit-package-offer-modal.component.scss']
})
export class OdmEditPackageOfferModalComponent {

  @Input()
  selectedAccess: string;
  @Output()
  changeRestrictedAccess: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('editOfferModal') editOfferModal: CommonModalComponent;

  show() {
    this.editOfferModal.show();
  }
  hide() {
    this.editOfferModal.hide();
  }
  onChangeRestrictedAccess(access: string){
    this.selectedAccess = access;
  }

  onSaveRestrictedAccess() {
    this.changeRestrictedAccess.emit(this.selectedAccess);
  }

}
