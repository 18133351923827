import {Injectable} from '@angular/core';
import {WindowReferenceService} from './window-reference.service';
import {Documentation} from '../documentation/documentation.model';

export class FileDownloadEventDatalayer {
  event: string;
  document_id: string;
  document_name: string;
  document_type: string;

  constructor(documentId: string, documentName: string, documentType: string) {
    this.event = 'file_download';
    this.document_id = documentId;
    this.document_name = documentName;
    this.document_type = documentType;
  }
}

@Injectable({
  providedIn: 'root'
})

export class FileDownloadEventDatalayerService {
  private window;

  constructor(private windowRef: WindowReferenceService) {
    this.window = windowRef.nativeWindow;
  }

  private DataLayerTrigger(fileDownloadEventDatalayer: FileDownloadEventDatalayer) {
    if (fileDownloadEventDatalayer) {
      this.window.dataLayer.push(fileDownloadEventDatalayer);
    }
  }

  FileDownloadEvent(fileInfo: Documentation) {
    let document_id = fileInfo.id;
    let document_name= (fileInfo.fileName == fileInfo.id) ? fileInfo.title : fileInfo.fileName;
    let document_type= fileInfo.extension;
    let event = new FileDownloadEventDatalayer(document_id, document_name, document_type);
    this.DataLayerTrigger(event);
  }

  FileDownloadParameterEvent(documentId: string, documentName: string, documentType: string) {
    let event = new FileDownloadEventDatalayer(documentId, documentName, documentType);
    this.DataLayerTrigger(event);
  }
}
