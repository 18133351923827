import {Injectable} from '@angular/core';
import {WindowReferenceService} from './window-reference.service';
import {NavigationEnd} from '@angular/router';
import {User} from '../user/user';
import {Item, Project} from '../../project/shared/project-model';
import {SessionStorage} from "ngx-webstorage";
import {environment} from "../../../environments/environment";

export class PageViewEventDatalayer {
  event: string;
  visitor_type: string;
  user_group: string;
  digital_platform: string;
  environment: string;
  business_unit: string;
  digital_platform_country: string;
  business_divison: string;
  page_language: string;
  page_category: string;
  configurator_name: string;
  page_sub_category: string;
  page_sub_sub_category: string;
  page_title: string;
  page_top_category: string;
  project_name: string;
  project_id: string;
  template: string;

  constructor(urlEvent: NavigationEnd, user: User, currentProject: Project, currentItemct: Item, environment: any) {
    this.event = 'page_view';
    this.visitor_type = user.role;
    this.user_group = user.partnerName;
    this.digital_platform = 'EcoStruxure Power System Contractors';
    this.digital_platform_country = user.partnerCountry;
    this.environment = (environment.production) ? 'Production' : 'UAT';
    this.business_unit = 'Power System';
    this.business_divison = 'Energy Management';
    this.configurator_name = 'EcoStruxure Power System Contractors';
    this.page_language = user.preferredLanguage;
    this.page_category = '';
    this.page_sub_category = '';
    this.page_sub_sub_category = '';
    this.page_title = (currentItemct !== null) ? currentItemct.name : '';
    this.page_top_category = urlEvent.urlAfterRedirects;
    this.project_name = currentProject !== null ? currentProject.name : '';
    this.project_id = currentProject !== null ? currentProject.id : '';
    this.template = urlEvent.urlAfterRedirects.replace('/', '');
  }
}

@Injectable({
  providedIn: 'root'
})

export class PageViewEventDatalayerService {
  private window;

  @SessionStorage()
  user: User;
  @SessionStorage()
  currentProject: Project;
  @SessionStorage()
  currentItemct: Item;

  constructor(private windowRef: WindowReferenceService) {
    this.window = windowRef.nativeWindow;
  }

  private DataLayerTrigger(pageViewEventDatalayer: PageViewEventDatalayer) {
    if (pageViewEventDatalayer) {
      this.window.dataLayer.push(pageViewEventDatalayer);
    }
  }

  PageViewEvent(urlEvent: NavigationEnd) {
    if (this.user === null ) {
      return;
    }
    let event = new PageViewEventDatalayer(urlEvent, this.user, this.currentProject, this.currentItemct, environment);
    this.DataLayerTrigger(event);
  }
}
