import {Injectable} from '@angular/core';
import {WindowReferenceService} from './window-reference.service';

export class CtaClickEventDatalayer {
  event: string;
  cta_location: string;
  cta_name: string;
  cta_link: string;

  constructor(routerLocation: string, eventName: string, routerLink: string) {
    this.event = 'cta_click';
    this.cta_location = routerLocation;
    this.cta_name = eventName;
    this.cta_link = routerLink;
  }
}

@Injectable({
  providedIn: 'root'
})

export class CtaClickEventDatalayerService {
  private window;

  constructor(private windowRef: WindowReferenceService) {
    this.window = windowRef.nativeWindow;
  }

  private DataLayerTrigger(ctaClickEventDatalayer: CtaClickEventDatalayer) {
    if (ctaClickEventDatalayer) {
      this.window.dataLayer.push(ctaClickEventDatalayer);
    }
  }

  CtaClickEvent(routerLocation: string, eventName: string, routerLink: string) {
    let event = new CtaClickEventDatalayer(routerLocation, eventName, routerLink);
    this.DataLayerTrigger(event);
  }
}
