<app-common-modal #editOfferModal [name]="'createInfo'" [withFooter]="false" [title]="'T_PACKAGE_MANAGEMENT_RIGHTS_OFFER_MODAL_TITLE' | translate" size="modal-lg">
  <div modal-body class="form-horizontal">
    <app-odm-restricted-access-select
      [selectedAccess]="selectedAccess"
      (changeRestrictedAccess)="onChangeRestrictedAccess($event) ">
    </app-odm-restricted-access-select>
    <div class="edit-package-modal-footer">
      <button class="btn btn-default-white-background" (click)="hide()" translate>T_BUTTON_CANCEL</button>
      <button class="btn btn-default-white-background"
              (click)="onSaveRestrictedAccess(); hide()" translate>T_OFFER_DATA_MANAGEMENT_SAVE_RANGE_INFO_BUTTON
      </button>
    </div>
  </div>

</app-common-modal>
