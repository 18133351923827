import {Injectable} from '@angular/core';
import {WindowReferenceService} from './window-reference.service';
import {Item, Project} from '../../project/shared/project-model';
import {TreeNode} from 'primeng/api';
import {ReferenceBom} from '../../bom/shared/bom-model';
import {MySEStatusEnum} from "../model/mySEStatusEnum";
import {SessionStorage} from "ngx-webstorage";
import {RangeDiscount} from "../../admin/shared";

export class ViewCartBomEventDatalayer {
  event: string;
  project_id: string;
  project_name: string;
  number_of_configurations: number;
  ecommerce: any;

  constructor(configurationSize: number, currentProject: Project, eCommerce: any) {
    this.event = "view_cart";
    this.project_id = currentProject.id;
    this.project_name = currentProject.name;
    this.number_of_configurations = configurationSize;
    this.ecommerce = eCommerce;
  }
}


@Injectable({
  providedIn: 'root'
})

export class ViewCartBomEventDatalayerService {
  private window;

  @SessionStorage()
  currentItemct: Item;
  @SessionStorage()
  isNetPriceDisplayed: boolean;

  @SessionStorage()
  netPriceByPartnerDiscount: RangeDiscount[];

  constructor(private windowRef: WindowReferenceService) {
    this.window = windowRef.nativeWindow;
  }

  private DataLayerTrigger(viewCartBomEventDatalayer: ViewCartBomEventDatalayer) {
    if (viewCartBomEventDatalayer) {
      this.window.dataLayer.push(viewCartBomEventDatalayer);
    }
  }

  viewBomEvent(item: Item, currentProject: Project, currency: string, treeData:  TreeNode<any>[]) {
    let offerItems: {}[] = [];

    treeData.forEach((offerItemParent) => {
      (offerItemParent.children).forEach((offerItemChild) => {
        if (offerItemChild !== null && offerItemChild.data !== null && offerItemChild.data.editableQuantity) {
          let eventPrice = this.getEventPrice(offerItemChild.data);
          offerItems.push({
            item_id: (offerItemChild.data.id !== undefined) ? offerItemChild.data.id : '',
            item_name: offerItemChild.data.ref,
            item_brand: 'Schneider Electric',
            item_category: offerItemChild.data.category || offerItemParent.data.category,
            item_variant: '',
            item_list_name: offerItemChild.data.ref,
            price: eventPrice,
            quantity: offerItemChild.data.count,
            configuration_id: item.id,
            configuration_name: item.name
          });
          }
          (offerItemChild.children).forEach((offerItemSubChild) => {
            if (offerItemSubChild !== null && offerItemSubChild.data !== null && offerItemSubChild.data.editableQuantity === true) {
              let eventPrice = this.getEventPrice(offerItemSubChild.data);
              offerItems.push({
                item_id: (offerItemSubChild.data.id !== undefined) ? offerItemChild.data.id : '',
                item_name: offerItemSubChild.data.ref,
                item_brand: 'Schneider Electric',
                item_category: offerItemChild.data.category || offerItemParent.data.category,
                item_variant: '',
                item_list_name: offerItemSubChild.data.ref,
                price: eventPrice,
                quantity: offerItemSubChild.data.count,
                configuration_id: item.id,
                configuration_name: item.name
              });
            }
          });
      });
    });

    let ecommerce = {
      currency: currency,
      value: this.getTotalBomEventPrice(),
      items: offerItems
    }

    let event = new ViewCartBomEventDatalayer(offerItems.length, currentProject, ecommerce);
    this.DataLayerTrigger(event);
  }


  viewProjectBomEvent(totalPrice: number, currentProject: Project, currency: string, itemsProject: Item[], refsValue: ReferenceBom[]) {
    let itemCategoryData = {};

    itemsProject.forEach((itemselementData) => {
      (itemselementData.displayAccessories).forEach((accessoriesValue) => {
        itemCategoryData[accessoriesValue] = {name: itemselementData.name,id: itemselementData.id,type: 'Accessories'}
        itemCategoryData[itemselementData.name] = {name: itemselementData.name,id: itemselementData.id,type: 'Accessories'}
      });
      (itemselementData.components).forEach((componentValue) => {
        itemCategoryData[componentValue.id] = {name: itemselementData.name,id: itemselementData.id,type: componentValue.type}
        itemCategoryData[itemselementData.name] = {name: itemselementData.name,id: itemselementData.id,type: componentValue.type}
      });
    });

    let offerItems: {}[] = [];
    refsValue.forEach((offerValue) => {
      if (offerValue.id === undefined) {
        const itemCategoryDataKeys = Object.keys(itemCategoryData);
        itemCategoryDataKeys.forEach((itemCategoryDataKey) => {
          if ((offerValue.ref).includes(itemCategoryDataKey)) {
            offerItems.push({
              item_id: '',
              item_name: offerValue.ref,
              item_brand: 'Schneider Electric',
              item_category: itemCategoryData[itemCategoryDataKey]?.type,
              item_variant: '',
              item_list_name: offerValue.ref,
              price: (offerValue.discountUnitPrice) * (offerValue.count),
              quantity: offerValue.count,
              configuration_id: itemCategoryData[itemCategoryDataKey]?.id,
              configuration_name: itemCategoryData[itemCategoryDataKey]?.name
            });
          }
        });
      } else {
        offerItems.push({
          item_id: offerValue.id,
          item_name: offerValue.ref,
          item_brand: 'Schneider Electric',
          item_category: itemCategoryData[offerValue.id]?.type,
          item_variant: '',
          item_list_name: offerValue.ref,
          price: (offerValue.discountUnitPrice) * (offerValue.count),
          quantity: offerValue.count,
          configuration_id: itemCategoryData[offerValue.id]?.id,
          configuration_name: itemCategoryData[offerValue.id]?.name
        });
      }
    });

    let ecommerce = {
      currency: currency,
      value: totalPrice,
      items: offerItems
    }

    let event = new ViewCartBomEventDatalayer(offerItems.length, currentProject, ecommerce);
    this.DataLayerTrigger(event);
  }

  private getEventPrice(data: any) {
    if (this.isNetPrice() && data.discountedNetPrice !== null && data.discountedNetPrice !== undefined) {
      return data.discountedNetPrice;
    }
    if (!this.isNetPrice() && data.discountedPrice !== null && data.discountedNetPrice !== undefined) {
      return data.discountedPrice;
    }
    return -1
  }

  private getTotalBomEventPrice() {
    if (this.currentItemct.bom === null || this.currentItemct.bom === undefined) {
      return -1;
    }
    if (this.isNetPrice()) {
      return this.currentItemct.bom.totalNetPrice;
    }
    return this.currentItemct.bom.totalPrice;
  }


  isNetPrice() {
    return this.isPartnerNetPrice() || this.isNetPriceDisplayed;
  }

  private isPartnerNetPrice() {
    return this.currentItemct.totalPrice ? this.currentItemct.totalPrice.validFoNetPrice : false;
  }

}
