<app-common-modal #editAccessoryValueModal [name]="'editAccessoryValueModal'" [title]=" " [twoButtonsDialogs]="true" [withFooter]="false">

  <h4 modal-title class="modal-title" translate>T_OFFER_DATA_MANAGEMENT_ACCESSORY_VALUE_INFORMATION</h4>
  <div modal-body>
    <div *ngIf="availableAccessories">
      <h5 translate>T_ACCESSORY</h5>
      <p-dropdown [options]="availableAccessories" [(ngModel)]="editAccessoryValueForm.accessoryId" optionLabel="nameTranslated" optionValue="id" [autoDisplayFirst]="false"></p-dropdown>
    </div>
    <h5 translate>T_ACCESSORY_REFERENCE</h5>
    <p *ngIf="!editAccessoryValueForm.bslReference">
      <span translate>T_ACCESSORY_NO_REFERENCE</span>
    </p>
    <p *ngIf="editAccessoryValueForm.bslReference">
      <span>{{editAccessoryValueForm.bslReference}}</span>
      <button class="action-button se-icon" (click)="clickDeleteReferenceButton()">action_delete</button>
    </p>
    <button *ngIf="!editAccessoryValueForm.bslReference" class="btn btn-smart"  (click)="clickAddReferenceButton()" translate>
      <span class="se-icons">action_add_stroke</span>T_ACCESSORY_REFERENCE_ADD_BUTTON
    </button>
    <h5 translate>T_ACCESSORY_NAME</h5>

    <app-spinner *ngIf="isLoading" class="spinner" [sizeClass]="'medium'"></app-spinner>
    <div *ngIf="!isLoading">
      <div class="edit-accessory-value-name" *ngFor="let item of editAccessoryValueForm.value | keyvalue; trackBy: trackByFn">
        {{item.key}}: <input class="edit-accessory-value-name-input" type="text" [(ngModel)]="editAccessoryValueForm.value[item.key]" />
      </div>
    </div>

    <div class="edit-accessory-value-modal-footer">
      <button class="btn btn-default-white-background" (click)="hide()" translate>T_BUTTON_CANCEL</button>
      <button class="btn btn-default-white-background" [disabled]="!isValidForm()" (click)="clickOnSaveAccessoryValue(); hide()" translate>T_OFFER_DATA_MANAGEMENT_SAVE_ACCESSORY_BUTTON</button>
    </div>
  </div>


</app-common-modal>

<!--Modal add reference-->
<app-odm-add-modal #addReferenceModal
                                     (onAddReferenceButton)="addReference($event)"
                                     [selectedRange]="selectedRange"
                                     [allowedReferenceCategories]="allowedReferenceCategoriesForAccessories"
>
</app-odm-add-modal>
