import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {CommonModalComponent} from "../../../../shared/common-modal/common-modal.component";
import {CopyOptionPayload, CubicleOption, EditOptionPayload} from "../../../../shared/model/cubicleOption-model";
import {TreeNode} from "primeng/api";
import {TranslateService} from "@ngx-translate/core";
import {Reference} from "../../../../configuration/shared/model/reference";
import {SwitchBoardComponent} from "../../../../configuration/shared/model/component";
import {ComponentService} from "../../../../configuration/shared/services/component.service";

export class ReferenceDataCopy {
  reference: string;
  description: string;
  alreadyExist: boolean;
  toCopy: boolean;
}


@Component({
  selector: 'app-odm-copy-option-modal',
  templateUrl: './odm-copy-option-modal.component.html',
  styleUrls: ['./odm-copy-option-modal.component.less']
})
export class OdmCopyOptionModalComponent {

  @ViewChild('copyOptionModal', {static: false}) copyOptionModal: CommonModalComponent;
  @ViewChild('confirmationCopyOptionModal') confirmationCopyOptionModal: CommonModalComponent;
  @ViewChild('dt') dt: any;

  @Input()
  references: Reference[];
  @Input()
  rangeId: string;
  @Output()
  saveOption: EventEmitter<CopyOptionPayload> = new EventEmitter<CopyOptionPayload>();

  optionToCopy: CubicleOption;
  treeDataOption : TreeNode[] = [];
  referencesList: ReferenceDataCopy[] = [];
  components: SwitchBoardComponent[];
  sourceReference: string;
  constructor(private translateService: TranslateService,
              private componentService: ComponentService) { }


  hide() {
    this.copyOptionModal.hide();
  }
  show(option: CubicleOption, reference: string) {
    this.optionToCopy = option;
    this.sourceReference = reference;
    this.createTreeNodeOption();
    this.createReferencesList();
    this.copyOptionModal.show();
  }

  clickOnSaveOption() {
    let selectedReferences: string[] = [];
    this.referencesList.forEach(ref => {
      if (ref.toCopy) {
        selectedReferences.push(ref.reference);
      }
    });
    this.saveOption.emit({
      option: this.optionToCopy,
      rangeId: this.rangeId,
      references: selectedReferences
    });
  }

  getTranslation(key: string) {
    return this.translateService.instant(key);
  }

  createTreeNodeOption() {
    this.treeDataOption = [];
    this.treeDataOption.push({
      label: this.translateService.instant(this.optionToCopy.name),
      expanded: true,
      children: []
    });
    this.optionToCopy.values.forEach(value => {
      if (value.reference !== null) {
        this.treeDataOption[0].children.push({
          label: this.translateService.instant(value.reference.ref) + ' - ' + this.translateService.instant(value.value)
        });
      } else {
        this.treeDataOption[0].children.push({
          label: this.translateService.instant(value.value)
        });
      }
    });
  }

  createReferencesList() {
    this.referencesList = []
    this.componentService.getComponentsByRangeId(this.rangeId).subscribe(components => {
      this.components = components;
      this.references.forEach(ref => {
        if (this.sourceReference !== ref.ref) {
          this.referencesList.push({
            reference: ref.ref,
            description: this.translateService.instant(ref.description),
            alreadyExist: this.alreadyExist(ref),
            toCopy: false
          });
        }
      });
    });
  }

  alreadyExist(reference: Reference): boolean {
    let switchBoardComponent: SwitchBoardComponent = this.components.find(component => component.reference.ref === reference.ref);
    if (switchBoardComponent === null || switchBoardComponent === undefined) {
      return false;
    }
    let isExists = false;
    switchBoardComponent.options.forEach(option => {
        if (this.translateService.instant(option.name) === this.translateService.instant(this.optionToCopy.name)) {
          isExists = true;
        }
      }
    );
    return isExists
  }

  showConfirmationCopyOption(){
    this.confirmationCopyOptionModal.show();
  }

  isValid(): boolean {
    let searchReferenceToCopy = this.referencesList.find(ref => ref.toCopy === true);
    return searchReferenceToCopy !== null && searchReferenceToCopy !== undefined;
  }
  resetToCopyFlags() {
    this.referencesList.forEach((ref) => {
      ref.toCopy = false;
    });
  }

  onSearchInputChange(event: any) {
    this.resetToCopyFlags();
    this.dt.filterGlobal(event.target.value, 'contains');
  }
}
