import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
/* app modules */
import {Documentation} from '../../shared/documentation/documentation.model';
import {DocumentStamp} from './document-stamp.model';
import {User} from '../../shared/user/user';

@Injectable()
export class StaticDocumentationService {

  constructor(private httpClient: HttpClient) {
  }

  /**
   * filter static documents by language
   * @param {Documentation[]} allDocuments
   * @param {string} language for us it is the language on session
   * @returns {Documentation[]} array of documents in specified language
   */
  static filterDocumentsByUserLanguage(allDocuments: Documentation[], language: string) {
    return allDocuments.filter(document => document.language &&
      document.language.toUpperCase() === language.toUpperCase()
    );
  }

  /**
   * Retrieve bsl documents by configuration
   * @param itemId configuration id name
   * @returns {Observable<R>}
   */
  retrieveBslDocumentationByItem(itemId: string): Observable<Array<Documentation>> {
    return this.httpClient.get<Array<Documentation>>('/documents/bslFiles/' + itemId);
  }

  /**
   * Retrieve documents by reference and range (BSL)
   * @param rangeId
   * @returns {Observable<R>}
   */
  retrieveComponentBslDocumentation(rangeId: string): Observable<Array<{[k: string]: Documentation}>> {
    return this.httpClient.get<Array<{[k: string]: Documentation}>>('/documents/range/' + rangeId);
  }

  /**
   * Download the document as a blob
   * @param projectId id of the project of which the documentation is related
   * @param itemId id of item of which the documentation is related
   * @param country of the user
   * @param language the current language
   * @param fileName name of the pdf
   * @param stamp if there is a need to add info to the static document
   * @returns {Observable<Response>}
   */
  generateStaticDocument(projectId: string, itemId: string, country: string, language: string,
                         fileName: string, stamp: DocumentStamp): Observable<HttpResponse<Blob>> {
    return this.httpClient.post('/export/download/' + projectId + '/' + itemId,
      {
        language: language,
        country: country.toLowerCase() === 'demo' ? 'in' : country.toLowerCase(), // directories are not case-sensitive in Windows OS
        docName: fileName,
        stamperInformation: stamp
      },
      {observe: 'response', responseType: 'blob'});
  }

  /**
   * Download a dynamic project document as a blob
   * @param projectId id of the project of which the documentation is related
   * @param country of the user
   * @param language the current language
   * @param fileName name of the pdf
   * @param stamp if there is a need to add info to the static document
   * @returns {Observable<Response>}
   */
  generateDynamicProjectDocument(projectId: string, country: string, language: string,
                         fileName: string, stamp: DocumentStamp, deliveryTime: number): Observable<HttpResponse<Blob>> {
    return this.httpClient.post('/export/download/' + projectId,
      {
        language: language,
        country: country.toLowerCase() === 'demo' ? 'in' : country.toLowerCase(), // directories are not case-sensitive in Windows OS
        docName: fileName,
        stamperInformation: stamp,
        deliveryTime: deliveryTime
      },
      {observe: 'response', responseType: 'blob'});
  }


  /**
   * Download project document as a blob
   * @param projectId id of the project of which the documentation is related
   * @param country of the user
   * @param language the current language
   * @param fileName name of the pdf
   * @param stamp if there is a need to add info to the static document
   * @param isDynamic if the doc to generate is the dynamic project document
   * @returns {Observable<Response>}
   */
  generateProjectDocument(projectId: string, country: string, language: string,
                          fileName: string, stamp: DocumentStamp, isDynamic: boolean, deliveryTime: number): Observable<HttpResponse<Blob>> {
    return isDynamic ? this.generateDynamicProjectDocument(projectId, country, language, fileName, stamp, deliveryTime) :
      this.generateStaticDocument(projectId, projectId, country, language, fileName, stamp);
  }

  /**
   * Get the localized term of use url
   * @param user
   */
  getTermsOfUseUrl(user: User) {
    if (user === null) {
      return './assets/doc/terms_of_use_en.pdf';
    }

    // Variant for the specific country or bilingual document (example english tos for indonesia)
    let countryVariant = '';
    if (user.partnerCountry === 'ID' || user.partnerCountry === 'NL' || user.partnerCountry === 'BE') {
      countryVariant = '_' + user.partnerCountry;
    }
    switch (user.preferredLanguage) {
      case 'en':
        return './assets/doc/terms_of_use_en' + countryVariant + '.pdf';
      case 'fr':
        return './assets/doc/terms_of_use_fr' + countryVariant + '.pdf';
      case 'ru':
        return './assets/doc/terms_of_use_ru' + countryVariant + '.pdf';
      case 'pt':
        return './assets/doc/terms_of_use_pt' + countryVariant + '.pdf';
      case 'nl':
        return './assets/doc/terms_of_use_nl' + countryVariant + '.pdf';
      default:
        return './assets/doc/terms_of_use_en.pdf';
    }
  }


}
