<app-common-modal #editOptionModal [name]="'addOption'" [title]=" " [twoButtonsDialogs]="true" [withFooter]="false">
  <h4 modal-title class="modal-title" translate>T_OFFER_DATA_MANAGEMENT_ACCESSORY_INFORMATION</h4>
  <div modal-body>

    <h5 translate>T_OPTION_REFERENCE</h5>
    <p-dropdown
      class="edit-option-dropdown"
      [options]="referencesList"
      [(ngModel)]="editOptionForm.reference"
      [autoDisplayFirst]="false"
      scrollHeight="150px">
    </p-dropdown>

    <h5 translate>T_OPTION_NAME</h5>
    <div class="edit-option-name" *ngFor="let item of editOptionForm.name | keyvalue; trackBy: trackByFn">
      <span class="edit-option-name-label">{{item.key}}:</span>
      <input class="edit-option-name-input" type="text" [(ngModel)]="editOptionForm.name[item.key]" />
    </div>

    <h5 translate>T_OPTION_DESCRIPTION</h5>
    <div class="edit-option-description" *ngFor="let item of editOptionForm.description | keyvalue; trackBy: trackByFn">
      <span class="edit-option-description-label">{{item.key}}:</span>
      <input class="edit-option-description-input" type="text" [(ngModel)]="editOptionForm.description[item.key]" />
    </div>

    <h5 *ngIf="showOnPopupManagement" translate class="checkbox-onpopup">
      <div>
        <label class="custom-checkbox-container">
          <input type="checkbox" class="checkbox" [(ngModel)]="editOptionForm.onPopUp" name="onPopUpOption">
          <span class="checkmark"></span>
        </label>
      </div>
      T_ADMIN_OFFER_DATA_MANAGEMENT_OPTION_ON_POP_UP
    </h5>

    <div class="edit-option-modal-footer">
      <button class="btn btn-default-white-background" (click)="hide()" translate>T_BUTTON_CANCEL</button>
      <button class="btn btn-default-white-background" [disabled]="!isValidForm()" (click)="clickOnSaveOption(); hide()" translate>T_OFFER_DATA_MANAGEMENT_SAVE_OPTION_BUTTON</button>
    </div>
  </div>

</app-common-modal>
