import {Component, EventEmitter, Input, Output} from '@angular/core';
import {RestrictedAccessAvailable} from "../restricted-access.enum";

@Component({
  selector: 'app-odm-restricted-access-select',
  templateUrl: './odm-restricted-access-select.component.html',
  styleUrls: ['./odm-restricted-access-select.component.scss']
})
export class OdmRestrictedAccessSelectComponent{

  @Input()
  selectedAccess: string;
  @Output()
  changeRestrictedAccess: EventEmitter<string> = new EventEmitter<string>();
  protected readonly RestrictedAccessAvailable = RestrictedAccessAvailable;
  onChangeRestrictedAccess(access: string){
    this.selectedAccess = access;
    this.changeRestrictedAccess.emit(access);
  }
}
