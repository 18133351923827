<app-common-modal #copyOptionModal [name]="'addOption'" [title]=" " [twoButtonsDialogs]="true" [withFooter]="false"
                  [size]="'modal-xxl'">
  <h4 modal-title class="modal-title" translate>T_OFFER_DATA_MANAGEMENT_COPY_OPTION_MODAL_TITLE</h4>
  <div modal-body >
    <div class="copy-option-modal-info">
      <span class="se-icons brand-information">information_circle</span>
      <label translate>T_COPY_OPTION_INFO</label>
    </div>
    <p-tree class="copy-option-modal-tree" [value]="treeDataOption" aria-expanded="true"></p-tree>

    <div class="copy-option-modal-table scrollable">
      <p-table #dt
                 [value]="referencesList"
                 responsiveLayout="stack"
                 [globalFilterFields]="['reference','description']"
                 [rowHover]="true"
                 [scrollable]="true" scrollHeight="400px" >
        <ng-template pTemplate="caption">
          <div class="table-caption">
            <div class="table-caption-search-bar p-input-icon-left ml-auto">
              <span class="pi pi-search table-caption-search-bar-search-icon"></span>
              <input pInputText type="text" (input)="onSearchInputChange($event)"
                     placeholder="Search"/>
            </div>
          </div>
        </ng-template>
          <ng-template pTemplate="header">
            <tr>
              <th scope="col" [pSortableColumn]="'reference'" [ngClass]="'reference-col'" translate >T_REFERENCE
                <p-sortIcon field="titleTranslated"></p-sortIcon>
              </th>
              <th scope="col" [pSortableColumn]="'description'" translate>T_DESCRIPTION
                <p-sortIcon field="fileName"></p-sortIcon>
              </th>
              <th scope="col" [pSortableColumn]="'alreadyExists'"  translate [ngClass]="'checkbox-col'">T_OPTION_ALREADY_EXISTS
              </th>
              <th scope="col" [pSortableColumn]="'toCopy'" [ngClass]="'checkbox-col'" translate>T_OPTION_TO_COPY
              </th>
            </tr>

          </ng-template>
          <ng-template pTemplate="body" let-ref>
            <tr>
              <td [ngClass]="'reference-col'"><span translate>{{ref.reference}}</span></td>
              <td><span>{{getTranslation(ref.description)}}</span></td>
              <td [ngClass]="'checkbox-col'">
                <label class="custom-checkbox-container">
                  <input type="checkbox" class="checkbox" [(ngModel)]="ref.alreadyExist" name="alreadyExist" disabled>
                  <span class="checkmark"></span>
                </label>
              </td>
              <td [ngClass]="'checkbox-col'">
                <label class="custom-checkbox-container">
                  <input type="checkbox" class="checkbox" [(ngModel)]="ref.toCopy" name="alreadyExist" >
                  <span class="checkmark"></span>
                </label></td>
            </tr>
          </ng-template>
        </p-table>
      <div class="copy-option-modal-footer">
        <button class="btn btn-default-white-background" (click)="hide()" translate>T_BUTTON_CANCEL</button>
        <button class="btn btn-default-white-background" [disabled]="!isValid()" (click)="showConfirmationCopyOption()" translate>
          T_OFFER_DATA_MANAGEMENT_SAVE_OPTION_BUTTON
        </button>
      </div>

    </div>


  </div>


</app-common-modal>

<app-common-modal #confirmationCopyOptionModal [name]="'confirmationCopyOptionModal'" (onClickYesButton)="clickOnSaveOption(); hide()"
                  [description]="'T_COPY_OPTION_MODAL_MESSAGE' | translate" >
</app-common-modal>
