<div class="container">
  <h1 translate>T_SETTINGS_TITLE</h1>
  <form class="form-horizontal" role="form">

    <fieldset>
      <legend translate>T_SETTINGS_PARTNER</legend>

      <div class="form-group">
        <label for="partnerName" class="col-xs-3 control-label" translate>T_SETTINGS_PARTNER_NAME</label>
        <div class="col-xs-5">
          <input type="text" disabled class="form-control" id="partnerName"
                 [value]="user.partnerName">
        </div>
      </div>

      <div class="form-group">
        <label for="partnerId" class="col-xs-3 control-label" translate>T_SETTINGS_PARTNER_ID</label>
        <div class="col-xs-5">
          <input type="text" disabled class="form-control" id="partnerId"
                 [value]="user.partnerId">
        </div>
      </div>

    </fieldset>

    <fieldset>
      <legend translate>T_SETTINGS_USER</legend>
      <div class="form-horizontal">

        <div class="form-group">
          <label for="firstName" class="col-xs-3 control-label" translate>T_SETTINGS_USER_FIRST_NAME</label>
          <div class="col-xs-5">
            <input type="text" disabled class="form-control" id="firstName"
                   [value]="user.firstName">
          </div>
        </div>

        <div class="form-group">
          <label for="lastName" class="col-xs-3 control-label" translate>T_SETTINGS_USER_LAST_NAME</label>
          <div class="col-xs-5">
            <input type="text" disabled class="form-control" id="lastName"
                   [value]="user.lastName">
          </div>
        </div>

        <div class="form-group">
          <label for="mail" class="col-xs-3 control-label" translate>T_SETTINGS_USER_MAIL</label>
          <div class="col-xs-5">
            <input type="text" disabled class="form-control" id="mail"
                   [value]="user.email">
          </div>
        </div>

        <div class="form-group">
          <label for="preferredLanguage" class="col-xs-3 control-label" translate>T_SETTINGS_USER_LANG</label>
          <div class="col-xs-5">
            <select class="form-control" id="preferredLanguage" required
                    (change)="selectLanguage($event.target.value)">
              <ng-container *ngFor="let lang of localization.langs">
                <option *ngIf="lang.available" [value]="lang.language"
                        [selected]="lang.language === user.preferredLanguage"
                        translate>{{'T_LANG_' + lang.language | uppercase}}
                </option>
              </ng-container>
            </select>
          </div>
        </div>

        <div *ngIf="user.currentMySEAccount" class="form-group">
          <label for="mySEAccount" class="col-xs-3 control-label" translate>T_SETTINGS_MYSE_ACCOUNT</label>
          <div class="col-xs-5">
            <select class="form-control" id="mySEAccount" required
                    (change)="changeMySEAccount($event.target.value)">
              <option *ngFor="let acc of user.mySEAccounts" [value]="acc.id"
                      [selected]="acc.id === user.currentMySEAccount.id"
                      translate>{{acc.name}}</option>
            </select>
          </div>
        </div>

        <div *ngIf="isAllowedToChange()" class="form-group">
          <label for="localization" class="col-xs-3 control-label" translate>T_LOCALIZATION</label>
          <div class="col-xs-5">
            <select class="form-control" id="localization" required
                    (change)="selectLocalization($event.target.value)">
              <option *ngFor="let loc of allLocalizations" [value]="loc.country"
                      [selected]="loc.country === user.partnerCountry"
                      translate>{{loc.country}}</option>
            </select>
          </div>
        </div>

        <div *ngIf="isAllowedToChange()" class="form-group">
          <label for="role" class="col-xs-3 control-label" translate>T_ROLE</label>
          <div class="col-xs-5">
            <select class="form-control" id="role" required
                    (change)="selectRole($event.target.value)">
              <option *ngFor="let role of roles" [value]="role"
                      [selected]="role === user.role"
                      translate>{{role}}</option>
            </select>
          </div>
        </div>




      </div>
    </fieldset>

    <div class="text-center">
      <button *ngIf="showButton" type="button" class="btn btn-default-gray-background btn-settings" (click)="goToProjectPage()" translate>
        T_VALIDATE
      </button>
    </div>

  </form>

  <div *ngIf="canManagePersonalData()">
    <h1 translate>T_SETTINGS_PERSONAL_DATA_TITLE</h1>
    <p translate>T_SETTINGS_PERSONAL_DATA_DESCRIPTION</p>
    <p><a [href]="getPrivacyProtectionLink()" target="_blank" translate>T_SETTINGS_PERSONAL_DATA_DESCRIPTION_LINK</a></p>
    <div>
      <button *ngIf="showButton" type="button" class="btn btn-default-gray-background btn-settings" (click)="onRequestPersonalDataRemovalButton()" translate>
        T_SETTINGS_PERSONAL_DATA_BUTTON
      </button>
    </div>
  </div>
  <div class="btn-cookie">
    <!-- OneTrust Cookies Settings link -->
    <button type="button" title="Cookies" class="ot-sdk-show-settings" translate>T_COOKIE_SETTINGS</button>
  </div>
</div>
<app-common-modal #requestPersonalDataRemovalModal
                  [title]="'T_SETTINGS_PERSONAL_DATA_MODAL_TITLE' | translate"
                  [name]="'T_SETTINGS_PERSONAL_DATA_MODAL_TITLE'"
                  [description]="'T_SETTINGS_PERSONAL_DATA_MODAL_DESCRIPTION' | translate"
                  [acceptInnerHtml] = "true" (onClickYesButton)="requestPersonalDataRemoval()">
</app-common-modal>
