
<app-common-modal #editAccessoryModal [name]="'addAccessory'" [title]=" " [twoButtonsDialogs]="true" [withFooter]="false">
  <h4 modal-title class="modal-title" translate>T_OFFER_DATA_MANAGEMENT_ACCESSORY_INFORMATION</h4>
  <div modal-body>

    <h5 translate>T_ACCESSORY_CATEGORY</h5>
    <p-dropdown
      [options]="availableCategories"
      [(ngModel)]="editAccessoryForm.categoryId"
      optionLabel="nameTranslated"
      optionValue="id"
      [autoDisplayFirst]="false"
      scrollHeight="150px">
    </p-dropdown>

    <h5 translate  *ngIf="displayName()">T_ACCESSORY_NAME</h5>
    <div class="edit-accessory-name" *ngFor="let item of editAccessoryForm.name | keyvalue; trackBy: trackByFn">
      {{item.key}}: <input class="edit-accessory-name-input" type="text" [(ngModel)]="editAccessoryForm.name[item.key]" />
    </div>

    <h5 translate *ngIf="displayDescription()">T_ACCESSORY_DESCRIPTION</h5>
    <div class="edit-accessory-name" *ngFor="let item of editAccessoryForm.description | keyvalue; trackBy: trackByFn">
      {{item.key}}: <input class="edit-accessory-name-input" type="text" [(ngModel)]="editAccessoryForm.description[item.key]" />
    </div>

    <h5 translate class="checkbox-mandatory">
      <div>
        <label class="custom-checkbox-container">
          <input type="checkbox" class="checkbox" [(ngModel)]="editAccessoryForm.required" name="mandatoryAccessory">
          <span class="checkmark"></span>
        </label>
      </div>
      T_ADMIN_OFFER_DATA_MANAGEMENT_ACCESSORY_MANDATORY
    </h5>

    <div *ngIf="enableDefaultValueManagement()">
      <h5 translate>T_ACCESSORY_DEFAULT_VALUE</h5>
      <p-dropdown
        [options]="accessoryValues"
        [(ngModel)]="editAccessoryForm.defaultValue"
        optionLabel="nameTranslated"
        optionValue="id"
        autoDisplayFirst="false"
        scrollHeight="150px">
      </p-dropdown>
    </div>

    <h5 translate>T_ACCESSORY_HTML_TYPE</h5>
    <p-selectButton [options]="elementTypeOptions" [(ngModel)]="editAccessoryForm.htmlElementType" optionLabel="name" optionValue="code"></p-selectButton>

    <div class="edit-accessory-modal-footer">
      <button class="btn btn-default-white-background" (click)="hide()" translate>T_BUTTON_CANCEL</button>
      <button class="btn btn-default-white-background" [disabled]="!isValidForm()" (click)="clickOnSaveAccessory(); hide()" translate>T_OFFER_DATA_MANAGEMENT_SAVE_ACCESSORY_BUTTON</button>
    </div>
  </div>

</app-common-modal>
