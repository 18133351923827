import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {CommonModalComponent} from '../../../../shared/common-modal/common-modal.component';
import {OfferDataManagementService} from '../../offer-data-management.service';
import {Range} from '../../../../shared/model/range-model';
import {BslReference} from '../../../shared/bsl-reference/bsl-reference-model';
import {MessageService} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {LoggerService} from '../../../../shared/logging/logger.service';
import {CubicleSld} from '../../../shared/bsl-reference/cubicle-sld-model';
import {CubicleLayout} from '../../../shared/bsl-reference/cubicle-layout-model';
import {OfferDataManagementHelper} from '../../../shared/util/offer-data-management-helper';

@Component({
  selector: 'app-odm-add-modal',
  templateUrl: './odm-add-modal.component.html',
  styleUrls: ['./odm-add-modal.component.less'],
})
export class OdmAddModalComponent {

  @ViewChild('addReferenceModal', {static: false}) addReferenceModal: CommonModalComponent;

  @Input()
  selectedRange: Range;
  @Input()
  allowedReferenceCategories: String[] = [];

  @Output() onAddReferenceButton = new EventEmitter();

  allBslReferences: BslReference[] = [];
  showList = true;
  filteredList: string[] = [];
  reference = '';
  loading = false;

  @ViewChild('sldModal', {static: false}) sldModal: CommonModalComponent;
  @ViewChild('layoutModal', {static: false}) layoutModal: CommonModalComponent;

  sldList: CubicleSld[];
  selectedSld: CubicleSld[];
  loadingSld = false;
  layoutList: CubicleLayout[];
  selectedLayout: CubicleLayout[];
  loadingLayout = false;

  constructor(private readonly offerDataManagementService: OfferDataManagementService,
              private messageService: MessageService,
              private readonly translate: TranslateService,
              private readonly logger: LoggerService) {
  }

  /**
   * Show the modal
   */
  show() {
    this.loading = true;
    this.showList = true;
    this.addReferenceModal.show();
    this.allBslReferences = [];
    this.filteredList = [];
    this.selectedSld = [];
    this.selectedLayout = [];
    this.reference = '';
    if (this.selectedRange) {
      this.offerDataManagementService.getCommercialReferencesForRangeId(this.selectedRange.id).subscribe(
        ListBslReference => {
          this.allBslReferences = ListBslReference.filter(ref =>
            !ref.category || this.allowedReferenceCategories.includes(ref.category));
          this.filteredList = this.allBslReferences.map(bslRef => bslRef.reference);
          this.loading = false;
        },
        () => {
          this.loading = false;
          this.messageService.add({
            severity: 'error',
            summary: this.translate.instant('T_ERROR'),
            detail: this.translate.instant('T_OFFER_DATA_MANAGEMENT_ERROR_REFERENCE'),
          });
        }
      );
    }
  }

  /**
   * Function called to filter references on list when user change the reference input
   */
  filterReferences() {
    this.filteredList = this.allBslReferences
      .filter(ref => ref.reference.toUpperCase().includes(this.reference.toUpperCase()))
      .map( ref => ref.reference);
  }

  /**
   * function called when user click on search icon
   */
  search() {
    this.showList = !this.showList;
    this.filterReferences();
  }

  /**
   * Function to hide the modal
   */
  hide() {
    this.addReferenceModal.hide();
    this.reference = '';
    this.selectedSld = [];
    this.selectedLayout = [];
  }

  /**
   * Function called when user click on add reference
   */
  addReference() {
    if (this.isValidForm()) {
      this.onAddReferenceButton.emit({bslReference: this.getBslReferenceFromReference(),
        sld: this.selectedSld, layout: this.selectedLayout});
      this.hide();
    }
  }

  /**
   * Function called when user click on add SLD
   * Get sld list by range id.
   */
  getSLDList() {
    this.sldList = [];
    this.loadingSld = true;
    this.offerDataManagementService.getAllSLDByRangeId(this.selectedRange.bslRangeId).subscribe(
      allSld => {
        this.sldList = allSld;
        this.loadingSld = false;
      },
      error => {
        this.loadingSld = false;
        this.logger.error(error);
        this.messageService.add({
          severity: 'error',
          summary: this.translate.instant('T_ERROR'),
          detail: this.translate.instant('T_OFFER_DATA_MANAGEMENT_SLD_RETRIEVING_ERROR'),
        });
      }
    );
    this.sldModal.show();
  }

  /**
   * Get sld to add on reference
   * @param sld
   */
  getSldToAddOnReference(sld) {
    this.selectedSld = sld ? sld : '';
    this.sldModal.hide();
  }


  /**
   * Function to check if the form is valid
   * It is valid if the reference selected if unique
   * and if it is not a cubicle. If it is a cubicle one, it has to have a sld selected
   */
  isValidForm(): boolean {
    return (this.isReferenceIncludedInListAndUnique()
    && (this.selectedSld.length !== 0 || !this.showSldAndLayoutSelector()));
  }

  /**
   * Return true if reference object exists in all references list and is unique
   * @returns {boolean}
   */
  isReferenceIncludedInListAndUnique(): boolean {
    return this.allBslReferences
      .filter(ref => ref.reference.toUpperCase() === this.reference.toUpperCase()).length === 1 ;
  }

  /**
   * Return BSL reference corresponding to the reference selected by user
   * @returns {BslReference}
   */
  getBslReferenceFromReference(): BslReference {
    return this.allBslReferences
        .filter(ref => ref.reference.toUpperCase() === this.reference.toUpperCase())[0];
  }

  /**
   * Return true if a reference is selected and is a cubicle
   * @returns {boolean} true if the reference selected is a cubicle
   */
  isCubicleSelected(): boolean {
    return this.isReferenceIncludedInListAndUnique() &&
      (this.getBslReferenceFromReference().category === 'Cubicle'
        || this.getBslReferenceFromReference().category === 'Switchboard'
        || this.getBslReferenceFromReference().category === 'Three Functions'
        || this.getBslReferenceFromReference().category === 'Ring Main Unit'
        || this.getBslReferenceFromReference().category === 'Commercial reference');
  }

  /**
   * Used to display list of selected SLD in human-readable format
   * @returns {string}
   */
  displaySldList(): string {
    return this.selectedSld != null ? this.selectedSld.map(sld => this.translate.instant(sld.nameKey)).join('-') : '';
  }


  /**
   * Used to display list of selected Layout in human-readable format
   * @returns {string}
   */
  displayLayoutList(): string {
    return this.selectedLayout != null ?
      this.selectedLayout.map(layout => OfferDataManagementHelper.getSvgFileNameWithoutExtension(layout.pictureFileName)).join('-')
      : '';
  }

  /**
   * Function called when user click on add Layout
   * Get layout list by range id.
   */
  getLayoutList() {
    this.layoutList = [];
    this.loadingLayout = true;
    this.offerDataManagementService.getAllLayoutByRangeId(this.selectedRange.bslRangeId).subscribe(
      allLayout => {
        this.layoutList = allLayout;
        this.loadingLayout = false;
      },
      error => {
        this.loadingLayout = false;
        this.logger.error(error);
        this.messageService.add({
          severity: 'error',
          summary: this.translate.instant('T_ERROR'),
          detail: this.translate.instant('T_OFFER_DATA_MANAGEMENT_LAYOUT_RETRIEVING_ERROR'),
        });
      }
    );
    this.layoutModal.show();
  }

  /**
   * Get layout to add on reference
   * @param layout
   */
  getLayoutToAddOnReference(layout) {
    this.selectedLayout = layout ? layout : '';
    this.layoutModal.hide();
  }

  showSldAndLayoutSelector() {
    return this.isCubicleSelected() && this.selectedRange.bslRangeId;
  }
}
