/* Angular modules */
import {Injectable} from '@angular/core';
/* ngx modules */
import {TranslateService} from '@ngx-translate/core';
import {SessionStorage} from 'ngx-webstorage';
/* 3rd parties libraries */
import * as _ from 'lodash';
import * as moment from 'moment';
import ObjectID from 'bson-objectid';
/* Project dependencies*/
import {Bom} from '../../bom/shared/bom-model';
import {MySEStatusEnum} from '../model/mySEStatusEnum';
import {DeliveryPolicy} from '../model/deliveryPolicy';
import {Item, Project, ProjectFile, Status} from '../../project/shared/project-model';
import {User} from '../user/user';
import {SwitchBoardComponent} from '../../configuration/shared/model/component';
import {RangeStatus} from '../model/range-model';
import {RightsService} from '../rights/rights.service';
import {ProjectService} from '../../project/shared/project.service';
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
@Injectable()
export class UtilService {

  static emailRegEx = new RegExp('^(([^<>()[\\]\\\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\\\.,;:\\s@\\"]+)*)' +
    '|(\\".+\\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])' +
    '|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))(\\,\\s*(([^<>()[\\]\\\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\\\.,;:\\s@\\"]+)*)' +
    '|(\\".+\\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,})))*$');

  static regExPhoneFax = new RegExp('^[\+]?[(]?[0-9]{1,2}[-\\s@./]?[0-9]?[)]?[-\\s@\.]?[0-9]{2}[-\\s@.]?[0-9][-\\s@\.]?[0-9-\\s@.]{1,10}$');

  static regDistributorPhone = new RegExp('^[\+]?[(]?[0-9]{2}[-\\s@./]?[0-9]?[)]?[-\\s@\.]?[0-9]{2}[-\\s@.]?[0-9][-\\s@\.]?[0-9-\\s@.]{4,10}$');

  static regAlphaNumeric = new RegExp(/^[a-zA-Z0-9]*$/);

  static regExNameFiledExcludeCaracters = new RegExp(/^[^\!\+\<\>\&\=\"\'\\\/\%\;\:\?\#\{\}\^\~\[\]]+$/);

  static embededPrefix = 'https://www.youtube.com/embed/';

  dataPrivacyPolicyPrefix = 'https://www.se.com/';

  dataPrivacyPolicySuffix = '/about-us/legal/data-privacy.jsp';

  stringDoesNotContainSpecialChars (str: string): boolean {
    // test if contains ! or < or > or & or = or " or ' or / or \ or % or ; or : or ? or # or { or } or | or ^ or ~ or [ or ] or `
    return !(/(\+|!|<|>|&|=|\"|\'|\/|\\|%|;|:|\?|#|\{|\}|\||\^|~|\[|\]|`)/.test(str));
  }

  @SessionStorage()
  isNetPriceDisplayed: boolean;


  constructor(private translateService: TranslateService,
              private rightsService: RightsService,
              private projectService: ProjectService,
              private httpClient: HttpClient) {
  }

  /**
   * Get default item name
   *
   * @param projects projects list
   * @param defaultName default name
   * @returns {string}
   */
  public static getDefaultName(projects: Array<any>, defaultName): string {
    const defaultRegexp = new RegExp('^' + defaultName + '\\s*(\\d*)$', 'i');
    const num = _.max(projects.map(project => project.name)
      .map(pName => {
        const matches = pName.trim().match(defaultRegexp);
        return matches ? (matches[1] ? parseInt(matches[1], 10) + 1 : 2) : null;
      }));

    return [defaultName, num].join(' ').trim();
  }


  /**
   * Function to format a price as number
   * with space to separate millier and dot to separate decimals
   * @param numb
   * @returns {string}
   */
  public static formatPrice(numb): string {
    if (null == numb) {
      return null;
    }
    if (numb === 'containsNullPrices'){
      return numb;
    }
    let numberFormated: string;

    if (typeof numb == 'number') {
      numberFormated = numb.toFixed(2);
    }
    numberFormated += '';
    const sep = ' ';
    const reg = /(\d+)(\d{3})/;
    while (reg.test(numberFormated)) {
      numberFormated = numberFormated.replace(reg, '$1' + sep + '$2');
    }
    return numberFormated;
  }

  /**
   * Function to apply a discount on a price and format it
   * @param price initial
   * @param discount to apply
   * @returns {string} the price discounted and formatted
   */
  public static formatDiscountPrice(price: any, discount: number): string {
    if (null == price) {
      return null;
    }
    if (null == discount) {
      discount = 0;
    }
    let myPrice: number;
    if (typeof price === 'string') {
      myPrice = parseFloat(price.replace(/ /g, ''));
    } else {
      myPrice = price;
    }
    return UtilService.formatPrice(myPrice * (1 - (discount / 100)));
  }

  /**
   * Method which apply the discount from a number and return a number (round with 2 digits)
   * @param price the initial price
   * @param discount the discount (in %) to apply
   * @returns {number} the price with the discount
   */
  public static applyDiscountPrice(price: number, discount: number): number {
    if(null == price) {
      return null;
    }
    if(null == discount) {
      discount = 0;
    }
    return parseFloat(UtilService.formatDiscountPrice(price, discount).replace(/ /g, ''));
  }

  /**
   * Get the total price: apply the discount on the total price and add the packing price
   * @param {MySEStatusEnum} mySePublicPriceItemStatus
   * @param {MySEStatusEnum} mySeNetPriceItemStatus
   * @param {Bom} bom the BOM
   * @param {number} discount
   * @returns {string} the total net price and formatted
   */
  public static getFormattedTotalNetPriceFromBOM(mySePublicPriceItemStatus: MySEStatusEnum, mySeNetPriceItemStatus: MySEStatusEnum, bom: Bom, discount: number): string {
    if (null == mySeNetPriceItemStatus && null == mySePublicPriceItemStatus) {
      return this.formatPrice(0);
    } else if (mySeNetPriceItemStatus === MySEStatusEnum.OK) {
      return this.formatPrice(this.applyDiscountPrice(bom.totalNetPrice, discount) + (bom.packagingPrice ? bom.packagingPrice : 0));
    } else if (mySePublicPriceItemStatus === MySEStatusEnum.OK) {
      return this.getFormattedTotalDiscountPriceFromBOM(bom, false, discount);
    }
    return this.formatPrice(0);
  }

  /**
   * Get the total price: apply the discount on the total price and add the packing price
   * @param {Bom} bom the BOM
   * @param {boolean} isPartnerNetPrice true if the partner net price are displayed
   * @param {number} discount to apply
   * @returns {string} the price discounted and formatted
   */
  public static getFormattedTotalDiscountPriceFromBOM(bom: Bom, isPartnerNetPrice: boolean, discount: number): string {
    let formatPrice;
    formatPrice = this.formatPrice(this.applyDiscountPrice(isPartnerNetPrice ? bom.totalNetPrice : bom.totalPrice, discount) + (bom.packagingPrice ? bom.packagingPrice : 0));
    return formatPrice;
  }

  /**
   * Get the total price for Project BOM: apply the discount on the configured item
   * @param currentProject
   */
  public static getFormatedTotalDiscountPriceFromProjectBOM(currentProject: Project): string {
    let totalPrice = 0;
    currentProject.itemCT.forEach(item => {
      if (Status.configured === item.status || Status.quoted === item.status) {
        totalPrice += this.applyDiscountPrice(item.bom.totalPrice, item.discount);
      }
    });
    return this.formatPrice(totalPrice);
  }

  /**
   * Function to format dimension
   * @param height
   * @param width
   * @param depth
   * @returns {any}
   */
  public static formatDimension(height: number, width: number, depth: number): string {
    return height + '*' + width + '*' + depth;
  }

  /**
   * Clone an object
   *
   * @param object
   * @returns {any}
   */
  public static clone(object: any) {
    return JSON.parse(JSON.stringify(object));
  }

  /**
   * Return the MySE status of an object with a reference or a referenceBom
   * @param myObject
   * @param isNetPriceStatus what type of status is asked
   * @returns MySEStatusEnum
   */
  public static getMySEStatusFromObjectWithReference(myObject: any): MySEStatusEnum {
    if (myObject.referenceBom) {
      return myObject.referenceBom.mySeNetPriceStatus;
    }
    if (myObject.reference) {
      return myObject.reference.mySeNetPriceStatus;
    }
    return MySEStatusEnum.NOT_APPLICABLE;
  }

  /**
   * Return the delivery time
   * @param deliveryTime
   * @param deliveryPolicy
   */
  public static calculateDeliveryTime(deliveryTime: number, deliveryPolicy: DeliveryPolicy) {
    if (deliveryPolicy !== null && deliveryPolicy.additionalTime != null && deliveryTime > 0) {
      return deliveryTime + deliveryPolicy.additionalTime;
    }
    return deliveryTime;

  }

  /**
   * Check constraints on phone/fax.
   * @param value
   */
  public static isPhoneFaxValueInvalid(value: string): boolean {
    return value && !value.match(this.regExPhoneFax);
  }

  /**
   * Return true if the email adress has a valid format
   * @param emailAdress
   */
  public static isValidEmailAdress(emailAdress): boolean {
    if (null == emailAdress) {
      return false;
    }
    return (emailAdress.match(this.emailRegEx));
  }

  /**
   * Return true if the phone number has a valid format for distributor screen
   * @param phoneNumber
   */
  public static isValidDistributorPhoneNumber(phoneNumber): boolean {
    if (null == phoneNumber) {
      return false;
    }
    return (phoneNumber.match(this.regDistributorPhone));
  }

  /**
   * Make ascendant sort between two words
   * @returns {number} a before b return 1
   */
  static alphabeticalSorter(a: string, b: string): number {
    return a.toLowerCase().localeCompare(b.toLowerCase());
  }

  /**
   * Function to download a document
   * @param blob the content
   * @param fileName the filename
   */
  static downloadDocument(blob: Blob, fileName: any | string) {
    if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) { // for IE
      (window.navigator as any).msSaveOrOpenBlob(blob, fileName);
    } else {// For others navigators
      const a = document.createElement('a');
      document.body.appendChild(a);
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    }
  }

  /**
   * Get user manual url
   * @param user
   */
  static getUserManualUrl(user: User) {
    let userManualFile = UtilService.getUserManualFile(user.partnerCountry, user.preferredLanguage);
    if (this.isFileExists(userManualFile)) {
      return userManualFile;
    }
    return UtilService.getUserManualFile('en', 'en');
  }

  private static isFileExists (url: string): boolean {
    const request = new XMLHttpRequest();
    request.open('HEAD', url, false);
    request.send();
    // The request is Ok if it's either 200 ( OK Http code) or 304 (resource did not change since last call)
    return request.status === 200 || request.status === 304;
  }
  /**
   * Return true if the text in parameter is empty of contains just spaces
   * @param text
   * @returns {boolean}
   */
  static isStringEmpty(text: string): boolean {
    if (null == text || text.length === 0) {
      return true;
    } else {
      return !(/\S/.test(text));
    }
  }


  /**
   * At least 1 of  prices came from mySE is valid
   * used for export BOM
   * @param {Item[]} items list of items
   * @returns {boolean} prices are valid on mySE
   */
  static itemsHaveValidPrices(items: Item[]): boolean {
    return items.some((item) =>
      item.components.some(component =>
        component.reference !== null &&
        (UtilService.isValidMySEPriceFromStatus(component.reference.mySeNetPriceStatus)) ||
          component.options.some(opt =>
            opt.values.some(val =>
              val.selected && val.reference !== null && UtilService.isValidMySEPriceFromStatus(val.reference.mySeNetPriceStatus))) ||
        component.functionalUnits.some(fu =>
          fu.transformer !== null && fu.transformer.reference !== null &&
          UtilService.isValidMySEPriceFromStatus(fu.transformer.reference.mySeNetPriceStatus)))
      || item.accessories.some(acc =>
      acc.reference !== null && (UtilService.isValidMySEPriceFromStatus(acc.reference.mySeNetPriceStatus))));
  }


  /**
   * Return true if the price with the MySE status in parameter can be displayed
   * Else return false
   * @param mySEItemStatus
   * @returns {boolean}
   */
  static isValidMySEPriceFromStatus(mySEItemStatus): boolean {
    return (mySEItemStatus !== MySEStatusEnum.NO_REFERENCE
      && mySEItemStatus !== MySEStatusEnum.NO_PRICE
      && mySEItemStatus !== MySEStatusEnum.NOT_REACHABLE);
  }

  /**
   * Returns true if we can order this item
   * @param item
   */
  static isOrderableItem(item: Item): boolean {
    return (Status.quoted === item.status && item.dateValidityQuotation && item.dateValidityQuotation > new Date().getTime() &&
      (!!item.selectedPackage || (!!item.range && item.range.status !== RangeStatus.DECOMMISSIONED)));
  }

  /**
   * Convert youtube url to embeded youtube url
   * @param videoLink
   */
  static convertYoutubeUrlToEmbededUrl(videoLink: string): string {
    if (videoLink !== null && videoLink !== '' &&
      ! videoLink.includes(this.embededPrefix)) {
      return this.embededPrefix + this.getVideoId(videoLink);
    }
  }

  /**
   * Extract youtube video Id from youtube URL
   * @param videoLink
   */
  private static getVideoId(videoLink: string): string {
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    const match = videoLink.match(regExp);
    return (match && match[7].length === 11) ? match[7] : videoLink;
  }

  /**
   * Return user manual file name from country and language
   * @param country
   * @param language
   * @returns user manual file
   */
  private static getUserManualFile(country: string, language: string): string {
    if (country !== null && country !== undefined &&
      language !== null && language !== undefined) {
      return './assets/doc/user_guide_' + country.toLocaleLowerCase() + '_' +
        language.toLocaleLowerCase() + '.pdf';
    }
    return '';
  }

  /**
   * Return true if the total price of an item can be displayed
   * Else return false
   * @param currentItemCT
   * @returns {boolean}
   */
  isValidMySEPrice(currentItemCT: Item): boolean {
    return UtilService.isValidMySEPriceFromStatus(currentItemCT.mySeNetPriceStatus);
  }


  /**
   * Localize a date by its locale
   * @param date the date
   * @param language locale language
   * @returns {string} localized date
   */
  public getLocalizedDate(date, language) {
    moment.locale(language);
    return moment(date).format('LL'); // Month dd, yyyy format for US
  }

  /**
   * Return true if the total price of an project can be displayed
   * @param currentProject
   */
  public isValidMySEPriceForProjectBom(currentProject): boolean {
    let isValid = true;
    currentProject.itemCT.forEach(item => {
      isValid = isValid && UtilService.isValidMySEPriceFromStatus(item.mySeNetPriceStatus);
    });
    return isValid;
  }

  /**
   * Return true if the price is valid (net or public) for a project
   * Else return false
   * @param currentProject the project
   * @returns {boolean}
   */
  public isValidMySENetPriceProject(currentProject: Project): boolean {
    let allNetPrices = true;
    currentProject.itemCT.forEach(item => {
      allNetPrices = allNetPrices && UtilService.isValidMySEPriceFromStatus(item.mySeNetPriceStatus);
    });
    return allNetPrices;
  }

  /**
   * Return the good MySE tooltip from the status as parameter
   * @param mySEItemStatus
   * @returns {any}
   */
  public getTooltipMySEMessageFromStatus(mySEItemStatus): string {
    if (MySEStatusEnum.NO_PRICE === mySEItemStatus) {
      return this.translateService.instant('T_MYSE_PRICE_NOT_EXISTS');
    }
    if (MySEStatusEnum.NO_REFERENCE === mySEItemStatus) {
      return this.translateService.instant('T_MYSE_REFERENCE_NOT_EXISTS');
    }
    if (MySEStatusEnum.NOT_REACHABLE === mySEItemStatus) {
      return this.translateService.instant('T_MYSE_ERROR_TRY_AGAIN');
    }
    if (MySEStatusEnum.OK !== mySEItemStatus && !this.displayMySENetPrice()) {
      return this.translateService.instant('T_MYSE_ERROR_PUBLIC_PRICE');
    }
    if (MySEStatusEnum.OK !== mySEItemStatus && this.displayMySENetPrice()) {
      return this.translateService.instant('T_MYSE_ERROR_NET_PRICE');
    }
    return '';
  }

  /**
   * Method which return true if we have to display net price
   */
  public displayMySENetPrice(): boolean {
    return environment.mySENetPrice && this.isNetPriceDisplayed;
  }

  /**
   * Return the good tooltip from MySE Status of the item
   * @returns {string} Tooltip message
   * @param {MySEStatusEnum} mySePublicPriceStatus
   * @param {MySEStatusEnum} mySeNetPriceStatus
   * @return {string} tooltip content or empty string
   */
  public getTooltipOnTotalMySEMessage(mySeNetPriceStatus: MySEStatusEnum): string {
    if (mySeNetPriceStatus !== MySEStatusEnum.OK) {
      return this.translateService.instant('T_MYSE_TOTAL_ERROR_NET_PRICE');
    }

    return '';
  }

  public getMySEStatusToSend(component: SwitchBoardComponent) {
    if (null != component && null != component.reference) {
      if (null != component.reference.netPrice) {
        return MySEStatusEnum.OK;
      } else if  (null != component.reference.mySeNetPriceStatus) {
        return component.reference.mySeNetPriceStatus;
      }
    }
    return null;
  }

  /**
   * Return author name. Return YOU Key if the author is the current user
   * @param projectFile
   * @param user
   * @returns {any}
   */
  public getAuthorName(projectFile: ProjectFile, user: User): string {
    if (projectFile.authorId === user.id) {
      return this.translateService.instant('T_YOU');
    } else {
      return projectFile.authorName;
    }
  }

  /**
   * Return true if we should display price, false otherwise
   * @param user
   * @param currentProject
   */
  public displayPrice(currentProject: Project) {
    return !this.rightsService.isActivatedDemo()
      && !this.rightsService.isDeactivatedDemo()
      && !this.projectService.isCpqProject(currentProject);
  }

  /**
   * Get Schneider Electric Data Privacy Policy data url
   * @param user current user
   */
  public getDataPrivacyPolicyUrl(user: User) {
    if (user === null) {
      return this.buildDataPrivacyPolicyUrlForLanguage( 'en');
    } else {
      return this.buildDataPrivacyPolicyUrlForLanguage(user.preferredLanguage.toLocaleLowerCase());
    }
  }

  public generateObjectId(): string {
    return new ObjectID().toString();
  }

  public static isValidString (str: string): boolean {
    // test if contains < or > or & or = or " or ' or / or \ or % or ; or : or ? or # or { or } or | or ^ or ~ or [ or ] or `
    return !(/(\+|!|<|>|&|=|\"|\'|\/|\\|%|;|:|\?|#|\{|\}|\||\^|~|\[|\]|`)/.test(str));
  }

  /**
   * Build Schneider Electric Data Privacy Policy data url for a country user
   * @param preferredLanguage User's preferred language
   */
  private buildDataPrivacyPolicyUrlForLanguage(preferredLanguage: string): string {
    switch (preferredLanguage) {
      case 'fr':
        return this.dataPrivacyPolicyPrefix + 'ww/fr' + this.dataPrivacyPolicySuffix;
      case 'ru':
        return this.dataPrivacyPolicyPrefix + 'ru/ru' + this.dataPrivacyPolicySuffix;
      case 'pt':
        return this.dataPrivacyPolicyPrefix + 'br/pt' + this.dataPrivacyPolicySuffix;
      default:
        return this.dataPrivacyPolicyPrefix + 'ww/en' + this.dataPrivacyPolicySuffix;
    }
  }
}
