import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { ExportSldLayoutService } from '../../shared/export-sld-layout/export-sld-layout.service';
import { OptionsPanelService } from '../shared/services/options-panel.service';
import { ProjectService } from '../../project/shared/project.service';
import {LoggerService} from '../../shared/logging/logger.service';
import {SessionStorage} from 'ngx-webstorage';
import {Item} from '../../project/shared/project-model';
import {FileDownloadEventDatalayerService} from '../../shared/google-analytics/file-download-event-datalayer.service';


@Component({
  selector: 'app-configuration-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less']
})
export class HeaderComponent implements OnInit {

  @SessionStorage()
  currentItemct: Item;

  @Output()
  onTabEvent = new EventEmitter<boolean>();
  onSldTab = true;
  isExporting: boolean;

  constructor(private projectService: ProjectService,
              private logger: LoggerService,
              private optionsPanelService: OptionsPanelService,
              private exportSldLayoutService: ExportSldLayoutService,
              private messageService: MessageService,
              private translateService: TranslateService,
              private fileDownloadEventDatalayerService: FileDownloadEventDatalayerService) {
  }

  ngOnInit() {
    this.isExporting = false;
  }

  public toLayoutTab() {
    if (this.onSldTab && this.isExporting) {
      this.messageService.add({
        severity: 'warn',
        summary: this.translateService.instant('T_EXPORT_CANCELLED_WARNING_TITLE'),
        detail: this.translateService.instant('T_EXPORT_CANCELLED_WARNING_MESSAGE'),
      });
      this.isExporting = false;
    }

    this.onSldTab = false;
    this.onTabEvent.emit(this.onSldTab);
  }

  public toSldTab() {
    if (!this.onSldTab && this.isExporting) {
      this.messageService.add({
        severity: 'warn',
        summary: this.translateService.instant('T_EXPORT_CANCELLED_WARNING_TITLE'),
        detail: this.translateService.instant('T_EXPORT_CANCELLED_WARNING_MESSAGE'),
      });
      this.isExporting = false;
    }

    this.onSldTab = true;
    this.onTabEvent.emit(this.onSldTab);
  }

  /**
   * Create and download a PNG image of the currently displayed SLD
   */
  public exportSldAsPng() {
    this.isExporting = true;
    this.logger.business('Export SLD');
    this.fileDownloadEventDatalayerService.FileDownloadParameterEvent('exportSldAsPng' + ' - ' + this.currentItemct.id,'Export SLD' + ' - ' + this.currentItemct.name ,'png');
    this.exportSldLayoutService.exportSldAsPng()
      .finally(() => this.isExporting = false);
  }

  /**
   * Create and download a PDF document of the currently displayed SLD
   */
  public exportSldAsPdf() {
    this.isExporting = true;
    this.logger.business('Export SLD');
    this.fileDownloadEventDatalayerService.FileDownloadParameterEvent('exportSldAsPdf' + ' - ' + this.currentItemct.id,'Export SLD' + ' - ' + this.currentItemct.name,'pdf');
    this.exportSldLayoutService.exportSldAsPdf()
      .finally(() => this.isExporting = false);
  }

  /**
   * Create and download a PNG image of the currently displayed Layout
   */
  public exportLayoutAsPng() {
    this.isExporting = true;
    this.logger.business('Export Layout');
    this.fileDownloadEventDatalayerService.FileDownloadParameterEvent('exportLayoutAsPng' + ' - ' + this.currentItemct.id,'Export Layout' + ' - ' + this.currentItemct.name,'png');
    this.exportSldLayoutService.exportLayoutAsPng()
      .finally(() => this.isExporting = false);
  }

  /**
   * Create and download a PDF document of the currently displayed Layout
   */
  public exportLayoutAsPdf() {
    this.isExporting = true;
    this.logger.business('Export Layout');
    this.fileDownloadEventDatalayerService.FileDownloadParameterEvent('exportLayoutAsPdf' + '-' + this.currentItemct.id,'Export Layout' + '-' + this.currentItemct.name,'pdf');
    this.exportSldLayoutService.exportLayoutAsPdf()
      .finally(() => this.isExporting = false);
  }
}
