/* angular modules */
import {Component,} from '@angular/core';
/* ngx modules */
import {SessionStorage} from 'ngx-webstorage';
/* app modules */
import {Item, Project} from './project/shared/project-model';
import {User} from './shared/user/user';
import {NavigationEnd, Router} from '@angular/router';
import {MessageService} from "primeng/api";
import {PageViewEventDatalayerService} from "./shared/google-analytics/page-view-event-datalayer.service";


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
  providers: [MessageService]
})
export class AppComponent {

  @SessionStorage()
  user: User;

  @SessionStorage()
  currentProject: Project;

  @SessionStorage()
  currentItemct: Item;

  constructor(
    private router: Router,
    private pageViewEventDatalayerService: PageViewEventDatalayerService) {
    this.handleRouteEvents();
  }

  handleRouteEvents() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.pageViewEventDatalayerService.PageViewEvent(event);
      }
    });
  }
}
