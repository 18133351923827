import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {Range, RangeStatus} from '../../../../shared/model/range-model';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {OfferDataManagementService} from '../../offer-data-management.service';
import {CommonModalComponent} from '../../../../shared/common-modal/common-modal.component';
import {TranslateService} from '@ngx-translate/core';
import {RangeType} from '../../../../shared/model/range-type';
import {
  EditRangeInfoPayload,
  OdmRangeManagementModalComponent
} from '../../shared/odm-range-management-modal/odm-range-management-modal.component';
import {
  OdmTransformToSelectorRangeModalComponent,
  TransformRangeInfoPayload
} from "../../shared/odm-transform-to-selector-range-modal/odm-transform-to-selector-range-modal.component";

@Component({
  selector: 'app-odm-list-offers',
  templateUrl: './odm-list-offers.component.html',
  styleUrls: ['./odm-list-offers.component.less']
})
export class OdmListOffersComponent {

  @Input()
  rangesObservable: Range[];

  @Input()
  titleHeader: string;

  // Allow user to reorder the ranges with drag and drop
  @Input()
  orderList: boolean;

  @Input()
  showImportExportButtons: boolean;

  @Input()
  loadingImportExport: boolean;

  @Input()
  loading: boolean;

  @Output()
  onClickOnComponentManagement: EventEmitter<Range> = new EventEmitter<Range>();

  @Output()
  onClickOnRangeConfiguration: EventEmitter<Range> = new EventEmitter<Range>();

  @Output()
  onChangeRangeOrder: EventEmitter<Range[]> = new EventEmitter<Range[]>();

  @Output()
  onClickOnRangeAddition: EventEmitter<Range> = new EventEmitter<Range>();

  @Output()
  onClickOnAccessoriesManagement: EventEmitter<Range> = new EventEmitter<Range>();

  @Output()
  onClickOnOptionsManagement: EventEmitter<Range> = new EventEmitter<Range>();

  @Output()
  onDecommissionRange: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  onClickRangeExport: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  onClickRangeImport: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  saveRangeInfo: EventEmitter<EditRangeInfoPayload> = new EventEmitter<EditRangeInfoPayload>();

  @Output()
  transformRangeInfo: EventEmitter<TransformRangeInfoPayload> = new EventEmitter<TransformRangeInfoPayload>();

  @Output()
  onTransformImportedRange: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  onPublishRange: EventEmitter<string> = new EventEmitter<string>();

  ranges: Range[];
  initialized = false;

  rangeToAction: string;

  @ViewChild('decommissionRangeModal') decommissionRangeModal: CommonModalComponent;
  @ViewChild('rangeManagementModal') rangeManagementModal: OdmRangeManagementModalComponent;
  @ViewChild('transformToSelectorRangeModal') transformToSelectorRangeModal: OdmTransformToSelectorRangeModalComponent;
  @ViewChild('transformToImportedRangeModal') transformToImportedRangeModal: CommonModalComponent;
  @ViewChild('publishRangeModal') publishRangeModal: CommonModalComponent;

  constructor(
    private readonly offerDataManagementService: OfferDataManagementService,
    private readonly translateService: TranslateService,
  ) {
  }

  rangeExport(rangeId: string) {
    this.onClickRangeExport.emit(rangeId)
  }

  rangeImport(event) {
    this.onClickRangeImport.emit(event);
  }

  /**
   * Range components has been selected
   * @param range
   */
  goToComponentManagement(range) {
    this.onClickOnComponentManagement.emit(range);
  }

  /**
   * One range has been clicked for own configuration purpose
   * @param range
   */
  goToRangeConfiguration(range) {
    this.onClickOnRangeConfiguration.emit(range);
  }

  /**
   * Range accessories has been selected
   * @param {Range} range the selected range  for accessory management
   */
  goToAccessoriesManagement(range) {
    this.onClickOnAccessoriesManagement.emit(range);
  }

  /**
   * Range options has been selected
   * @param {Range} range the selected range  for accessory management
   */
  goToOptionsManagement(range) {
    this.onClickOnOptionsManagement.emit(range);
  }

  /**
   * Range accessories has been selected
   * @param {Range} range the selected range  for accessory management
   */
  goToRangeAddition(range) {
    this.onClickOnRangeAddition.emit(range);
  }

  /**
   * Method to reorder list when user drop an item
   * @param event
   */
  drop(event: CdkDragDrop<any>) {
    moveItemInArray(this.rangesObservable, event.previousContainer.data, event.container.data);
    this.onChangeRangeOrder.emit(this.rangesObservable);
  }

  getActionsMenu(range: Range) {
    if (range.status === RangeStatus.PUBLISHED) {
      switch (range.rangeType) {
        case RangeType.ACCESSORIES:
        case RangeType.FAKE:
          return [{
            label: 'Actions',
            items: [
              {
                label: this.translateService.instant('T_OFFER_DATA_MANAGEMENT_COMPONENT_MANAGEMENT_TITLE'),
                id: 'componentManagement',
              }, {
                label: this.translateService.instant('T_OFFER_DATA_MANAGEMENT_ACCESSORY_MANAGEMENT_TITLE'),
                id: 'accessoryManagement',
              }, {
                label: this.translateService.instant('T_OFFER_DATA_MANAGEMENT_RANGE_MANAGEMENT_BUTTON'),
                id: 'rangeManagement',
              }, {
                label: this.translateService.instant('T_OFFER_DATA_MANAGEMENT_DECOMMISSION_RANGE'),
                id: 'decommissionRange',
              }
            ]
          }];
        case RangeType.TRANSFORMER:
          let listItemsAction = [
            {
              label: this.translateService.instant('T_OFFER_DATA_MANAGEMENT_COMPONENT_MANAGEMENT_TITLE'),
              id: 'componentManagement',
            }, {
              label: this.translateService.instant('T_OFFER_DATA_MANAGEMENT_ACCESSORY_MANAGEMENT_TITLE'),
              id: 'accessoryManagement',
            }, {
              label: this.translateService.instant('T_OFFER_DATA_MANAGEMENT_OPTIONS_MANAGEMENT_TITLE'),
              id: 'optionsManagement',
            }, {
              label: this.translateService.instant('T_OFFER_DATA_MANAGEMENT_RANGE_MANAGEMENT_BUTTON'),
              id: 'rangeManagement',
            }, {
              label: this.translateService.instant('T_OFFER_DATA_MANAGEMENT_DECOMMISSION_RANGE'),
              id: 'decommissionRange',
            }
          ];

          if (range.importedRange) {
            listItemsAction = listItemsAction.concat([
              {
                label: this.translateService.instant('T_OFFER_DATA_MANAGEMENT_TRANSFORM_TO_IMPORTED_RANGE'),
                id: 'transformToImportedRange',
              }
            ]);
          }


          return [{
            label: 'Actions',
            items: listItemsAction
          }];
        case RangeType.IMPORTED:
          return [{
            label: 'Actions',
            items: [
              {
                label: this.translateService.instant('T_OFFER_DATA_MANAGEMENT_COMPONENT_MANAGEMENT_TITLE'),
                id: 'componentManagement',
              }, {
                label: this.translateService.instant('T_OFFER_DATA_MANAGEMENT_ACCESSORY_MANAGEMENT_TITLE'),
                id: 'accessoryManagement',
              }, {
                label: this.translateService.instant('T_OFFER_DATA_MANAGEMENT_OPTIONS_MANAGEMENT_TITLE'),
                id: 'optionsManagement',
              }, {
                label: this.translateService.instant('T_OFFER_DATA_MANAGEMENT_RANGE_MANAGEMENT_BUTTON'),
                id: 'rangeManagement',
              }, {
                label: this.translateService.instant('T_PRICE_EXPORT'),
                id: 'exportRange',
              }, {
                label: this.translateService.instant('T_OFFER_DATA_MANAGEMENT_TRANSFORM_TO_SELECTOR_RANGE'),
                id: 'transformToSelectorRange',
              }, {
                label: this.translateService.instant('T_OFFER_DATA_MANAGEMENT_DECOMMISSION_RANGE'),
                id: 'decommissionRange',
              }
            ]
          }];
        case RangeType.SWITCHBOARD:
        default:
          return [{
            label: 'Actions',
            items: [
              {
                label: this.translateService.instant('T_OFFER_DATA_MANAGEMENT_COMPONENT_MANAGEMENT_TITLE'),
                id: 'componentManagement',
              }, {
                label: this.translateService.instant('T_OFFER_DATA_MANAGEMENT_ACCESSORY_MANAGEMENT_TITLE'),
                id: 'accessoryManagement',
              }, {
                label: this.translateService.instant('T_OFFER_DATA_MANAGEMENT_OPTIONS_MANAGEMENT_TITLE'),
                id: 'optionsManagement',
              }, {
                label: this.translateService.instant('T_OFFER_DATA_MANAGEMENT_RANGE_MANAGEMENT_BUTTON'),
                id: 'rangeManagement',
              }, {
                label: this.translateService.instant('T_OFFER_DATA_MANAGEMENT_DECOMMISSION_RANGE'),
                id: 'decommissionRange',
              }
            ]
          }];
      }
    }

    if (range.status === RangeStatus.CONFIGURED || range.status === RangeStatus.TEST) {
      if (range.importedRange) {
        return [{
          label: 'Actions',
          items: [
            {
              label: this.translateService.instant('T_OFFER_DATA_MANAGEMENT_COMPONENT_MANAGEMENT_TITLE'),
              id: 'componentManagement',
            }, {
              label: this.translateService.instant('T_OFFER_DATA_MANAGEMENT_ACCESSORY_MANAGEMENT_TITLE'),
              id: 'accessoryManagement',
            }, {
              label: this.translateService.instant('T_OFFER_DATA_MANAGEMENT_OPTIONS_MANAGEMENT_TITLE'),
              id: 'optionsManagement',
            }, {
              label: this.translateService.instant('T_OFFER_DATA_MANAGEMENT_RANGE_MANAGEMENT_BUTTON'),
              id: 'rangeManagement',
            }, {
              label: this.translateService.instant('T_OFFER_DATA_MANAGEMENT_TRANSFORM_TO_IMPORTED_RANGE'),
              id: 'transformToImportedRange',
            }, {
              label: this.translateService.instant('T_OFFER_DATA_MANAGEMENT_PUBLISH_RANGE'),
              id: 'publishRange',
            }, {
              label: this.translateService.instant('T_OFFER_DATA_MANAGEMENT_DECOMMISSION_RANGE'),
              id: 'decommissionRange',
            }
          ]
        }];
      } else {
        return [{
          label: 'Actions',
          items: [
            {
              label: this.translateService.instant('T_OFFER_DATA_MANAGEMENT_COMPONENT_MANAGEMENT_TITLE'),
              id: 'componentManagement',
            }, {
              label: this.translateService.instant('T_OFFER_DATA_MANAGEMENT_DECOMMISSION_RANGE'),
              id: 'decommissionRange',
            }
          ]
        }];
      }
    }

    if (range.status === RangeStatus.AVAILABLE) {
      return [{
        label: 'Actions',
        items: [
          {
            label: this.translateService.instant('T_OFFER_DATA_MANAGEMENT_ADD_RANGE_TITLE'),
            id: 'addRange',
          }
        ]
      }];
    }
    return null;
  }

  clickedOnActionMenu(action: string, range: Range) {
    switch (action) {
      case 'componentManagement':
        this.goToComponentManagement(range);
        break;
      case 'rangeConfiguration':
        this.goToRangeConfiguration(range);
        break;
      case 'accessoryManagement':
        this.goToAccessoriesManagement(range);
        break;
      case 'optionsManagement':
        this.goToOptionsManagement(range);
        break;
      case 'rangeManagement':
        this.showRangeManagementModal(range);
        break;
      case 'addRange':
        this.goToRangeAddition(range);
        break;
      case 'decommissionRange':
        this.showDecommissionRangeModal(range.id);
        break;
      case 'exportRange':
        this.rangeExport(range.id);
        break;
      case 'transformToSelectorRange':
        this.showTransformToSelectorRangeModal(range);
        break;
      case 'transformToImportedRange':
        this.showTransformToImportedRangeModal(range.id);
        break;
      case 'publishRange':
        this.showPublishRangeModal(range.id);
        break;
      default:
        break;
    }
  }

  showDecommissionRangeModal(rangeId: string) {
    this.rangeToAction = rangeId;
    this.decommissionRangeModal.show();
  }

  showRangeManagementModal(range: Range) {
    this.rangeManagementModal.show(range);
  }

  showTransformToSelectorRangeModal(range: Range) {
    this.transformToSelectorRangeModal.show(range);
  }

  showTransformToImportedRangeModal(rangeId: string) {
    this.rangeToAction = rangeId;
    this.transformToImportedRangeModal.show();
  }

  showPublishRangeModal(rangeId: string) {
    this.rangeToAction = rangeId;
    this.publishRangeModal.show();
  }
  decommissionRange(rangeId: string) {
    this.onDecommissionRange.emit(rangeId);
  }

  onTransformRangeImported(rangeId: string) {
    this.onTransformImportedRange.emit(rangeId);
  }
  publishRange(rangeId: string) {
    this.onPublishRange.emit(rangeId);
  }

  onSaveRangeInfo(rangeInfo: EditRangeInfoPayload) {
    this.saveRangeInfo.emit(rangeInfo);
  }

  onTransformRangeSelector(transformRange: TransformRangeInfoPayload) {
    this.transformRangeInfo.emit(transformRange);
  }

  getTitleTooltip() {
    if (this.showImportExportButtons) {
      return this.translateService.instant('T_OFFER_DATA_MANAGEMENT_IMPORT_PACKAGE_TOOLTIP');
    }
    if (this.titleHeader === 'T_MY_CURRENT_RANGES') {
      return this.translateService.instant('T_OFFER_STATUS_CONFIGURED_HELP_MESSAGE')
        + '. ' + this.translateService.instant('T_OFFER_STATUS_TEST_HELP_MESSAGE')
        + '. ' + this.translateService.instant('T_OFFER_STATUS_PUBLISHED_HELP_MESSAGE')
        + '. ';
    }
    return null;
  }

  getTranslation(key) {
    return this.translateService.instant(key);
  }

}
