import {Injectable} from '@angular/core';
import {WindowReferenceService} from './window-reference.service';
import {Item, Project} from '../../project/shared/project-model';
import {SessionStorage} from "ngx-webstorage";
import {RangeDiscount} from "../../admin/shared";

export class AddRemoveQuantityToCartEventDatalayer {
  event: string;
  project_id: string;
  project_name: string;
  number_of_configurations: number;
  ecommerce: any;

  constructor(event: string, currentProject: Project, eCommerce: any) {
    this.event = event;
    this.project_id = currentProject.id;
    this.project_name = currentProject.name;
    this.number_of_configurations = 1;
    this.ecommerce = eCommerce;
  }
}

@Injectable({
  providedIn: 'root'
})

export class AddRemoveQuantityToCartEventDatalayerService {
  private window;

  @SessionStorage()
  currentItemct: Item;

  @SessionStorage()
  currentProject: Project;

  @SessionStorage()
  isNetPriceDisplayed: boolean;

  @SessionStorage()
  netPriceByPartnerDiscount: RangeDiscount[];

  constructor(private windowRef: WindowReferenceService) {
    this.window = windowRef.nativeWindow;
  }

  private DataLayerTrigger(addRemoveQuantityToCartEventDatalayer: AddRemoveQuantityToCartEventDatalayer) {
    if (addRemoveQuantityToCartEventDatalayer) {
      this.window.dataLayer.push(addRemoveQuantityToCartEventDatalayer);
    }
  }

  AddRemoveQuantityToCartEvent(nodeDetails: any, currency: string, isAddQuantity: boolean) {
    let offerItems: {
      item_id: string,
      item_name: string,
      item_brand: string,
      item_category: string,
      item_variant: string,
      price: number,
      item_list_name: string,
      quantity: string,
      configuration_id: string,
      configuration_name: string
    }[] = [];

    let eventPrice = this.getEventPrice(nodeDetails);

    offerItems.push({
      item_id: nodeDetails.data.id,
      item_name: nodeDetails.data.ref,
      item_brand: 'Schneider Electric',
      item_category: nodeDetails.parent.data.category,
      item_variant: '',
      price: eventPrice,
      item_list_name: nodeDetails.data.ref,
      quantity: nodeDetails.data.count,
      configuration_id: (this.currentItemct !== null) ? this.currentItemct.id : '',
      configuration_name: (this.currentItemct !== null) ? this.currentItemct.name : ''
    });

    let ecommerce = {
      currency: currency,
      value: eventPrice,
      items: offerItems
    }

    let event;
    if (isAddQuantity) {
      event = new AddRemoveQuantityToCartEventDatalayer("add_to_cart", this.currentProject, ecommerce);
    } else {
      event = new AddRemoveQuantityToCartEventDatalayer("remove_from_cart", this.currentProject, ecommerce);
    }

    this.DataLayerTrigger(event);
  }

  private getEventPrice(nodeDetails: any) {

    const isNetPrice = this.netPriceByPartnerDiscount !== null && this.netPriceByPartnerDiscount.some(disc => disc.discount > 0) || this.isNetPriceDisplayed;
    let eventPrice = -1;
    if (!isNetPrice && nodeDetails.data.price !== null && nodeDetails.data.price !== undefined) {
      return parseFloat(nodeDetails.data.price.replace(/\s/g, ''));
    }
    if (isNetPrice && nodeDetails.data.netPrice !== null && nodeDetails.data.netPrice !== undefined) {
      // Net price has priority
      return parseFloat(nodeDetails.data.netPrice.replace(/\s/g, ''));
    }
    return -1;
  }
}
