import {Injectable} from '@angular/core';
import {WindowReferenceService} from './window-reference.service';
import {User} from '../user/user';

export class LoginEventDatalayer {
  event: string;
  company_id: number;
  company_name: string;
  customer_classification_category: string;
  customer_classification_sub_category: string;
  is_customer_impersonation: string;
  is_federated_id: string;
  is_schneider_employee: string;
  is_test_user: string;
  user_id: string;
  user_type: string;

  constructor(userInfo: User) {
    this.event = 'account_login';
    this.company_id = userInfo.partnerId;
    this.company_name = userInfo.partnerName;
    this.customer_classification_category = userInfo.role;
    this.customer_classification_sub_category = '';
    this.is_customer_impersonation = 'false';
    this.is_federated_id = 'true';
    this.is_schneider_employee = (userInfo.email).includes('se.com') ? 'true' : 'false';
    this.is_test_user = '';
    this.user_id = userInfo.id;
    this.user_type = userInfo.type;
  }
}

@Injectable({
  providedIn: 'root'
})

export class LoginEventDatalayerService {
  private window;

  constructor(private windowRef: WindowReferenceService) {
    this.window = windowRef.nativeWindow;
  }

  private DataLayerTrigger(loginEventDatalayer: LoginEventDatalayer) {
    if (loginEventDatalayer) {
      this.window.dataLayer.push(loginEventDatalayer);
    }
  }

  LoginEvent(userInfo: User) {
    if (userInfo === null) {
      return;
    }
    let event = new LoginEventDatalayer(userInfo);
    this.DataLayerTrigger(event);
  }
}
