<h5 translate>T_OFFER_DATA_MANAGEMENT_RESTRICTED_ACCESS</h5>
<div class="restricted-access-container" *ngFor="let restrictedAccess of RestrictedAccessAvailable">
  <input type="radio"
         class="pointer"
         [value]="selectedAccess" >
  <img *ngIf="restrictedAccess.key === selectedAccess"
       src="./assets/images/icons/radio.svg"
       class="radioBtn"/>
  <img *ngIf="restrictedAccess.key !== selectedAccess"
       (click)="onChangeRestrictedAccess(restrictedAccess.key)"
       src="./assets/images/icons/radioUnselected.svg"
       class="radioBtn"/>
  {{restrictedAccess.value | translate}}
</div>
