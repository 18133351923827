import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import * as _ from 'lodash';
import {SessionStorage} from 'ngx-webstorage';
import {environment} from '../../../environments/environment';
import {Item, Project} from '../../project/shared/project-model';
import {User} from '../user/user';
import {Role} from '../../core/access-control/enum';
import {PartialObserver} from "rxjs";


enum LogLevel {
  trace = 'default',
  debug = 'debug',
  info = 'info',
  warn = 'warning',
  error = 'error',
  business = 'business',
  success = 'success',
}

interface ContextObject {
  [key: string]: any;
}

export class TempoMessage {
  logLevel: LogLevel;
  message: string;
  objectToSend: ContextObject;

  constructor(logLevel: LogLevel, message: string, objectToSend?: ContextObject) {
    this.logLevel = logLevel;
    this.message = message;
    this.objectToSend = (objectToSend ? objectToSend : null);
  }
}

@Injectable()
export class LoggerService {

  @SessionStorage()
  currentProject: Project;

  @SessionStorage()
  localization;

  @SessionStorage()
  currentItemct: Item;

  @SessionStorage()
  user: User;
  constructor(private httpClient: HttpClient) {
  }

  public static isTraceEnabled() {
    return (environment.threshold === LogLevel.trace);
  }

  public static isDebugEnabled() {
    return (environment.threshold === LogLevel.trace || environment.threshold === LogLevel.debug);
  }

  public static isInfoEnabled() {
    return (environment.threshold === LogLevel.trace ||
      environment.threshold === LogLevel.debug || environment.threshold === LogLevel.info);
  }

  private static _logConsole(logLevel: LogLevel, message: string): void {
    let color1;
    switch (logLevel) {
      case LogLevel.business:
        color1 = 'blue';
        break;
      case LogLevel.debug:
        color1 = 'teal';
        break;
      case LogLevel.info:
      case LogLevel.trace:
        color1 = 'gray';
        break;
      case LogLevel.warn:
      case LogLevel.error:
        color1 = 'red';
        break;
      default:
        return;
    }
  }


  trace(message: string, objectContext?: ContextObject): void {
    if (LoggerService.isTraceEnabled()) {
      this._log(LogLevel.trace, message, objectContext);
    }
  }

  debug(message: string, objectContext?: ContextObject): void {
    if (LoggerService.isDebugEnabled()) {
      this._log(LogLevel.debug, message, objectContext);
    }
  }

  info(message: string, objectContext?: ContextObject): void {
    if (LoggerService.isInfoEnabled()) {
      this._log(LogLevel.info, message, objectContext);
    }
  }

  warn(message: string, objectContext?: ContextObject): void {
    this._log(LogLevel.warn, message, objectContext);
  }

  error(message: string, objectContext?: ContextObject): void {
    this._log(LogLevel.error, message, objectContext);
  }

  business(message: string, objectContext?: ContextObject): void {
    this._log(LogLevel.business, message, objectContext);
  }

  logStatus(item: Item) {
    this.business('Status: ' + item.status);
  }

  private _log(logLevel: LogLevel, message: string, objectContext?: ContextObject): void {
    this._logToServer(logLevel, message, objectContext);
  }

  private _logToServer(logLevel: LogLevel, message: string, objectContext?: ContextObject): void {
    LoggerService._logConsole(logLevel, message);
    // add specific info to the object;
    const objectToSend: ContextObject = {
      url: window.location.href,
      message: message,
      'log_target': logLevel.toString(),
      'user_agent': window.navigator.userAgent,
      'user_lang': window.navigator.language
    };
    // construct the object to send with some automatic data
    objectToSend.extraData = {};

    if (this.currentProject) {
      _.extend(objectToSend.extraData, {
        project_id: this.currentProject.id,
        project_name: this.currentProject.name,
        items: JSON.stringify(this.currentProject.itemCT.map(item => item.id))
      });
    }

    if (this.currentItemct) {
      // Total price login
      let publicTotal_Price = null;
      let total_partner_net_price = null;
      if (this.user.currentMySEAccount && environment.mySENetPrice) {
        // CASE : MySE with net price
        total_partner_net_price = this.currentItemct.totalPrice ? this.currentItemct.totalPrice.mySENetPrice : 0;
      } else {
        // CASE : No MySE access
        publicTotal_Price = this.currentItemct.totalPrice ? this.currentItemct.totalPrice.foPublicPrice : 0;
        total_partner_net_price = this.currentItemct.totalPrice ? this.currentItemct.totalPrice.foPublicPrice : 0;
      }

      // Apply discount if correct right
      if (this.user.role === Role.NATIONAL_SALES || this.user.role === Role.RESELLER ) {
        total_partner_net_price = total_partner_net_price != null ? total_partner_net_price * (1 - this.currentItemct.discount / 100) : null;
      }

      // FIXME find a way to translate the key in en
      _.extend(objectToSend.extraData, {
        range_name: !!this.currentItemct.range ? this.currentItemct.range.nameKey : 'T_PACKAGE_OFFER_NAME',
        item_id: this.currentItemct.id,
        item_name: this.currentItemct.name,
        total_public_price: publicTotal_Price,
        total_partner_net_price: total_partner_net_price,
        currency: this.localization.currency
      });
    }

    if (objectContext) {
      _.extend(objectToSend.extraData, objectContext);
    }

    const user: User = this.user;

    if (user) {
      objectToSend.user = user;
    }

    const loggerObserver:PartialObserver<any> = {
      error: error => LoggerService._logConsole(LogLevel.error, error.toString())
    };
    this.httpClient.post(environment.backendLoggerUrl, objectToSend).subscribe(loggerObserver);
  }
}
