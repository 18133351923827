import {Component, EventEmitter, Output, ViewChild} from '@angular/core';
import {CommonModalComponent} from '../../../../shared/common-modal/common-modal.component';
import {Range} from '../../../../shared/model/range-model';
import {ComponentService} from "../../../../configuration/shared/services/component.service";
import {Characteristic} from "../../../../configuration/shared/model/IProduct";

export class TransformRangeInfoPayload {
  rangeId: string;
  visibleFilters: string[];
}

@Component({
  selector: 'app-odm-transform-to-selector-range-modal',
  templateUrl: './odm-transform-to-selector-range-modal.component.html',
  styleUrls: ['./odm-transform-to-selector-range-modal.component.less']
})
export class OdmTransformToSelectorRangeModalComponent {
  @Output()
  transformRangeInfo: EventEmitter<TransformRangeInfoPayload> = new EventEmitter<TransformRangeInfoPayload>();

  @ViewChild('transformToSelectorRangeModal', {static: false}) transformToSelectorRangeModal: CommonModalComponent;

  range: Range;

  transformRangeInfoPayload: TransformRangeInfoPayload = {rangeId: null, visibleFilters: []};

  loading = false;
  isValid = false;

  availableCharacteristics: string[] = [];
  selectedCharacteristics: string[] = [];

  constructor(private componentService: ComponentService) {
  }

  show(range: Range){
    this.range = range;
    this.loading = true;
    this.componentService.getCharacteristicsForFilters(range.id).subscribe(characteristics => {
      this.availableCharacteristics = characteristics;
      this.loading = false;
    });
    this.transformRangeInfoPayload.rangeId = range.id;
    this.transformToSelectorRangeModal.show();
  }

  hide(){
    this.transformToSelectorRangeModal.hide();
  }


  clickOnSaveRangeButton(){
    this.selectedCharacteristics.forEach(characteristic => this.transformRangeInfoPayload.visibleFilters.push(characteristic));
    this.transformRangeInfo.emit(this.transformRangeInfoPayload);
  }

  calculateIsValid(){
    this.loading = true;
    //Check uniqueness and availability of selected characteristics
    this.componentService.checkUniqueness(this.range.id, this.selectedCharacteristics).subscribe(
      isValid => {
        this.isValid = isValid;
        this.loading = false;
      }

    );
    return this.isValid;
  }

  onCharacteristicChange(characteristics: string){
    let findIndex = this.selectedCharacteristics.indexOf(characteristics);
    if (findIndex > -1) {
      this.selectedCharacteristics.splice(findIndex, 1);
    } else {
      this.selectedCharacteristics.push(characteristics);
    }
    this.calculateIsValid();
  }

  isSelected(characteristics: string):boolean{
    return this.selectedCharacteristics.indexOf(characteristics) > -1;
  }

  isValidForm(){
    return this.selectedCharacteristics.length > 0 && this.isValid;
  }

}
