import {ComponentType} from './component-type';
import {Characteristic, Dimension, IProduct, Opt} from './IProduct';
import {Reference} from './reference';
import {Documentation} from '../../../shared/documentation/documentation.model';
import {RestrictedOrderingType} from '../../../shared/model/range-model';
/**
 * Class for the modelization of switchboard component
 * Possible types :
 *  - Cubicle
 *  - Transformer
 */
export class SwitchBoardComponent extends IProduct {
  type: ComponentType;
  extensible: string;
  functionalUnits: Array<FunctionalUnit>;
  ordering;
  position: number;
  incompatibilities: [Incompatibility];
  additivities;
  rangeId: string[];
  visible?: boolean;
  mySEAvailability: boolean;
  mySEAvailabilityDate: Date;

  constructor(id: string, name: string, productId: string, characteristics: [Characteristic], dimensions: Dimension, options: [Opt],
              reference: Reference, documents: Array<Documentation>, quantity: number, rangeId: Array<string>,
              quantityId: string, type: ComponentType, extensible: string,
              functionalUnits: Array<FunctionalUnit>, ordering, position: number, incompatibilities: [Incompatibility], additivities) {
    super(id, name, productId, characteristics, dimensions, options, reference, documents, quantity, quantityId);
    this.type = type;
    this.extensible = extensible;
    this.functionalUnits = functionalUnits;
    this.ordering = ordering;
    this.position = position;
    this.incompatibilities = incompatibilities;
    this.additivities = additivities;
  }

  /**
   * Method for displaying cubicle option selection popup when adding a component
   * @param component with or without option
   */
  static hasPopUpOption(component: SwitchBoardComponent): boolean {
    // No right panel on transformer selection so mandatory popup
    return (component.type === 'transformer' && component.options && component.options.length > 0) || component.options.some(opt => opt.onPopUp);
  }

  static getPopUpOptions(component: SwitchBoardComponent): Opt[] {
    const popOptions: Opt[] = [];
    if (component.type === 'transformer' && component.options.length) {
      return component.options;
    }
    component.options.forEach(option => {
      if (option.onPopUp) {
        popOptions.push(option);
      }
    });
    return popOptions.map(opt => {
      if (opt.defaultValue !== undefined && opt.defaultValue !== null) {
        opt.values[opt.defaultValue].selected = true;
      }
      return opt;
    });
  }
}


export class FunctionalUnit {
  name: string;
  description: string;
  electricalFunction?: string;
  synergridName?: string;
  sldKey: string;
  options?: Array<FunctionalUnitOption>;
  transformerCompatible: boolean;
  transformer: SwitchBoardComponent;
  // Using in transformer adding in cubicle case
  restrictedOrderingType?: RestrictedOrderingType;
  transformerRange?: string;
}

class FunctionalUnitOption {
}

export class Incompatibility {
  values: [string];
  idEntity?: string;
  typeEntity?: string;
}

export enum ComponentSearchModeEnum {
  ALL = 'ALL',
  MAIN = 'MAIN',
  COMPATIBLE = 'COMPATIBLE'
}

export class ReferenceToOrder {
  reference: string;
  offer: string;
  quantity: number;
  price: number;
}
