import {Component, OnInit, ViewChild} from '@angular/core';
import {Range, RangeStatus} from '../../shared/model/range-model';
import {OfferDataManagementService} from './offer-data-management.service';
import {CommonModalComponent} from '../../shared/common-modal/common-modal.component';
import {CustomTranslateLoader} from '../../shared/localization/CustomTranslateLoader';
import {SessionStorage} from 'ngx-webstorage';
import {TranslateService} from '@ngx-translate/core';
import {MessageService} from 'primeng/api';
import {UploadService} from '../shared';
import {LoggerService} from "../../shared/logging/logger.service";
import {EditRangeInfoPayload} from './shared/odm-range-management-modal/odm-range-management-modal.component';
import {
  TransformRangeInfoPayload
} from "./shared/odm-transform-to-selector-range-modal/odm-transform-to-selector-range-modal.component";

@Component({
  selector: 'app-offer-data-management',
  templateUrl: './offer-data-management.component.html',
  styleUrls: ['./offer-data-management.component.less'],
})
export class OfferDataManagementComponent implements OnInit {

  static IMPORT_OK_RESPONSE = 'IMPORT-OK';

  @SessionStorage()
  user;

  @SessionStorage()
  isOnAdministrationScreen = true;

  ranges: Range[];

  availableRanges: Range[];


  currentPage: "ComponentManagement" | "RangeConfiguration" | "AccessoriesManagement" | "OptionsManagement";
  selectedRange: Range;

  // useful to just save the chosen range before confirmation (because we stay on the selection page: selectedRange = null)
  selectedAvailableRange: Range;

  isConfigurationMode = false;
  loadingImportExport = false;
  odmLoading: boolean;
  bslLoading: boolean;
  rangeLoading: boolean;

  @ViewChild('addConfigurationModal') addConfigurationModal: CommonModalComponent;

  constructor(private logger: LoggerService,
              private messageService: MessageService,
              private translateService: TranslateService,
              private customTranslateLoader: CustomTranslateLoader,
              private offerDataManagementService: OfferDataManagementService,
              private uploadService: UploadService) {
  }

  ngOnInit() {
    this.initOdm();
  }

  initOdm() {
    this.odmLoading = true;
    this.bslLoading = true;
    this.offerDataManagementService.getAllExpressRanges().subscribe((ranges) => {
      this.ranges = ranges.filter(range => range.status !== RangeStatus.DECOMMISSIONED);
      this.odmLoading = false;
    })
    this.offerDataManagementService.getAllBslAvailableRanges().subscribe(ranges => {
      this.availableRanges = ranges;
      this.bslLoading = false
    });
  }

  /**
   * Method to enter a range and view current list of references or go to range configuration steps
   * @param {Range} range the selected range
   */
  goToComponentManagement(range: Range) {
    this.selectedRange = range;
    this.currentPage = "ComponentManagement";
  }

  goToRangeConfiguration(range: Range) {
    if (RangeStatus.AVAILABLE === range.status) {
      this.addConfigurationModal.show();
      this.currentPage = "RangeConfiguration";
      this.selectedAvailableRange = range;
    }
  }

  /**
   * Method to enter a range accessories and view current list of accessories
   * @param {Range} range the selected range for accessory management
   */
  goToAccessoriesManagement(range: Range) {
    this.currentPage = "AccessoriesManagement";
    this.selectedRange = range;
  }

  /**
   * Method to enter a range accessories and view current list of accessories
   * @param {Range} range the selected range for accessory management
   */
  goToOptionsManagement(range: Range) {
    this.currentPage = "OptionsManagement";
    this.selectedRange = range;
  }


  /**
   * Range must be updated
   * @param {Range} range
   */
  updateCurrentRange(range: Range) {
    this.selectedRange = range;
    let updatedRange = this.ranges.find(r => r.id === range.id);
    Object.assign(updatedRange, range);
  }

  /**
   * Method to call the backend service to update range order
   * @param {Range[]} listRanges
   */
  updateRangeOrder(listRanges: Range[]) {
    // we add decommissioned ranges
    this.offerDataManagementService.updateRangeOrder(listRanges).subscribe(
      () => {
        this.messageService.add({
          severity: 'success',
          summary: this.translateService.instant('T_INFO'),
          detail: this.translateService.instant('T_OFFER_DATA_MANAGEMENT_SUCCESS_SORTING_RANGES'),
        });
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: this.translateService.instant('T_ERROR'),
          detail: this.translateService.instant('T_OFFER_DATA_MANAGEMENT_ERROR_SORTING_RANGES'),
        });
      }
    );
  }

  /**
   * Save the current range before beginning the configuration steps
   */
  configurationSteps() {
    this.selectedRange = this.selectedAvailableRange;
    this.rangeLoading = true;
    this.offerDataManagementService.updateRange(this.selectedRange).subscribe(range => {
      if (range) {
        // Updating translation by forcing cache for the new offer translations key
        this.customTranslateLoader.getTranslation(this.user.preferredLanguage, true).subscribe(res => {
          this.translateService.setTranslation(this.user.preferredLanguage, res, false);
          this.selectedRange = range;
          // Adding new ranges to express ranges
          this.ranges.push(this.selectedRange);
          // Going in configuration mode
          this.isConfigurationMode = true;
          this.rangeLoading = false;
        });

      }
    });
  }

  openReferenceList(): boolean {
    return this.selectedRange &&
      (this.selectedRange.status === 'PUBLISHED' || this.selectedRange.importedRange === true) &&
      this.currentPage === 'ComponentManagement';
  }

  openAddOffer(): boolean {
    return this.isConfigurationMode ||
      (this.selectedRange && this.selectedRange.importedRange === false &&
        (this.selectedRange.status === 'CONFIGURED' || this.selectedRange.status === 'TEST'));
  }


  /**
   * Method to return on the range list
   */
  returnOnOfferSelection() {
    // FIXME: refresh ranges (get getAllExpressRanges and getAllBslAvailableRanges) if we call this method from the 'Previous' nav
    this.selectedRange = null;
    this.isConfigurationMode = false;
  }

  decommissionRange(rangeId: string) {
    this.odmLoading = true;
    this.offerDataManagementService.decommissionRange(rangeId).subscribe(() => {
        this.messageService.add({
          severity: 'success',
          summary: this.translateService.instant('T_INFO'),
          detail: this.translateService.instant('T_OFFER_DATA_MANAGEMENT_SUCCESS_DECOMMISSIONING_RANGE'),
        });
        this.initOdm();
      }, (error) => {
        this.messageService.add({
          severity: 'error',
          summary: this.translateService.instant('T_ERROR'),
          detail: this.translateService.instant('T_OFFER_DATA_MANAGEMENT_ERROR_DECOMMISSIONING_RANGE'),
        });
      }
    )
  }

  saveRangeInfo(rangeInfo: EditRangeInfoPayload) {
    this.offerDataManagementService.updateRangeInfo(rangeInfo).subscribe(() => {
      this.messageService.add({
        severity: 'success',
        summary: this.translateService.instant('T_INFO'),
        detail: this.translateService.instant('T_OFFER_DATA_MANAGEMENT_SUCCESS_UPDATING_RANGE'),
      });
      this.initOdm();
    }, (error) => {
      this.messageService.add({
        severity: 'error',
        summary: this.translateService.instant('T_ERROR'),
        detail: this.translateService.instant('T_OFFER_DATA_MANAGEMENT_ERROR_UPDATING_RANGE'),
      });
    });
  }

  transformRangeSelector(rangeInfo: TransformRangeInfoPayload) {
    this.offerDataManagementService.transformRangeSelector(rangeInfo).subscribe(() => {
      this.messageService.add({
        severity: 'success',
        summary: this.translateService.instant('T_INFO'),
        detail: this.translateService.instant('T_OFFER_DATA_MANAGEMENT_SUCCESS_UPDATING_RANGE'),
      });
      this.initOdm();
    }, (error) => {
      this.messageService.add({
        severity: 'error',
        summary: this.translateService.instant('T_ERROR'),
        detail: this.translateService.instant('T_OFFER_DATA_MANAGEMENT_ERROR_UPDATING_RANGE'),
      });
    });
  }

  transformRangeImported(rangeId: string) {
    this.offerDataManagementService.transformImportedRange(rangeId).subscribe(() => {
      this.messageService.add({
        severity: 'success',
        summary: this.translateService.instant('T_INFO'),
        detail: this.translateService.instant('T_OFFER_DATA_MANAGEMENT_SUCCESS_UPDATING_RANGE'),
      });
      this.initOdm();
    }, (error) => {
      this.messageService.add({
        severity: 'error',
        summary: this.translateService.instant('T_ERROR'),
        detail: this.translateService.instant('T_OFFER_DATA_MANAGEMENT_ERROR_UPDATING_RANGE'),
      });
    });
  }

  publishRange(rangeId: string) {
    this.offerDataManagementService.publishRange(rangeId).subscribe(() => {
      this.messageService.add({
        severity: 'success',
        summary: this.translateService.instant('T_INFO'),
        detail: this.translateService.instant('T_OFFER_DATA_MANAGEMENT_SUCCESS_UPDATING_RANGE'),
      });
      this.initOdm();
    }, (error) => {
      this.messageService.add({
        severity: 'error',
        summary: this.translateService.instant('T_ERROR'),
        detail: this.translateService.instant('T_OFFER_DATA_MANAGEMENT_ERROR_UPDATING_RANGE'),
      });
    });
  }

  rangeExport(rangeId: string) {
    this.loadingImportExport = true;
    this.offerDataManagementService.exportRanges(rangeId).subscribe(
      response => {
        this.uploadService.createXlsFileFromBackendResponse(response);
        this.loadingImportExport = false;
      }, (error) => {
        this.loadingImportExport = false;
      }
    );
  }

  rangeImport(event) {
    this.logger.info('upload range with reference file');
    const files = event.target.files;
    if (files.length > 0) {
      if (files[0].type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        this.messageService.add({
          severity: 'error',
          summary: this.translateService.instant('T_REFERENCES_IMPORT_FILE_TYPE_TILE'),
          detail: this.translateService.instant('T_REFERENCES_IMPORT_FILE_TYPE_MESSAGE'),
        });
        return;
      }
      const formData: FormData = new FormData();
      formData.append('file', files[0]);
      const uploadUrl = '/offer-data-management/ranges/import';
      this.loadingImportExport = true;
      this.uploadService.uploadFile(uploadUrl, formData).subscribe(importResponse => {
        this.loadingImportExport = false;
        if (importResponse === OfferDataManagementComponent.IMPORT_OK_RESPONSE) {
          this.messageService.add({
            severity: 'success',
            summary: this.translateService.instant('T_REFERENCES_IMPORT_SUCCESS_TITLE'),
            detail: this.translateService.instant('T_REFERENCES_IMPORT_SUCCESS_MESSAGE'),
          });
        } else {
          this.messageService.add({
            severity: 'error',
            summary: this.translateService.instant('T_REFERENCES_IMPORT_ERROR_TITLE'),
            detail: this.translateService.instant('T_REFERENCES_IMPORT_ERROR_MESSAGE'),
          });
          this.logger.info('download references file report');
          const downloadUrl = '/offer-data-management/ranges/report';
          this.uploadService.downloadReport(downloadUrl, importResponse).subscribe(
            response => {
              this.uploadService.createXlsFileFromBackendResponse(response);
            }
          );
        }
        // reload ranges datas
        this.customTranslateLoader.getTranslation(this.user.preferredLanguage, true).subscribe(res => {
          this.translateService.setTranslation(this.user.preferredLanguage, res, false);
          this.initOdm();
        });
      }, (error) => {
        this.messageService.add({
          severity: 'error',
          summary: this.translateService.instant('T_PRICE_IMPORT_ERROR_TITLE'),
          detail: this.translateService.instant('T_PRICE_IMPORT_ERROR_MESSAGE'),
        });
        this.loadingImportExport = false;
      });
    }
    // clean files in event
    event.target.value = null;
  }

}
