import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {CommonModalComponent} from '../../../../shared/common-modal/common-modal.component';
import {TranslationService} from '../../../shared';
import {TranslateService} from '@ngx-translate/core';
import {SessionStorage} from 'ngx-webstorage';
import {User} from '../../../../shared/user/user';
import {Range} from '../../../../shared/model/range-model';
import {OdmAddModalComponent} from '../../shared/odm-add-modal/odm-add-modal.component';
import {
  CubicleOption,
  CubicleOptionValue,
  EditOptionValuePayload
} from '../../../../shared/model/cubicleOption-model';
import {OptionsService} from "../../../../shared/services/options.service";

export type EditOptionValueForm = {
  name: string;
  value: {
    [key: string]: string;
  };
  description: {
    [key: string]: string;
  };
  bslReference?: string;
  valueId?: string;
}

@Component({
  selector: 'app-odm-edit-option-value-modal',
  templateUrl: './odm-edit-option-value-modal.component.html',
  styleUrls: ['./odm-edit-option-value-modal.component.less']
})
export class OdmEditOptionValueModalComponent {

  @Input()
  selectedRange: Range;
  @Output()
  saveOptionValue: EventEmitter<EditOptionValuePayload> = new EventEmitter<EditOptionValuePayload>();

  @ViewChild('editOptionValueModal', {static: false}) editOptionValueModal: CommonModalComponent;
  @ViewChild('addReferenceModal', {static: false}) addReferenceModal: OdmAddModalComponent;

  @SessionStorage()
  public user: User;

  availableOptions: any[];
  optionsByReference: Map<string, CubicleOption[]>;
  editOptionValueForm: EditOptionValueForm = {name: null, value: {}, description: {}};
  allowedReferenceCategoriesForOptions: string[];
  editedSwitchboardReference: string;
  editedOptionName: string;
  editedOptionValueName: string;
  isLoading: boolean = false;

  constructor(private optionsService: OptionsService,
              private translationService: TranslationService,
              private translateService: TranslateService) {
  }


  showAddMode(optionsByRef: Map<string, CubicleOption[]>){
    let references: string[] = [];

    this.editOptionValueForm = {name: null, value: {}, description: {}};
    this.optionsByReference = optionsByRef;
    for (const key in this.optionsByReference) {
      references.push(key);
    }

    this.editedSwitchboardReference = '';
    this.editedOptionName = '';
    this.availableOptions = []
    references.forEach(ref => {
      optionsByRef[ref].forEach(option => this.availableOptions.push({
          name: option.name,
          nameTranslated: ref + ' - ' + this.translateService.instant(option.name),
          reference : ref}));
    });

    this.translationService.getAllLanguagesByCountry(this.user.partnerCountry).subscribe(languages => {
      languages.forEach(lang => {
        this.editOptionValueForm.value[lang] = '';
        this.editOptionValueForm.description[lang] = '';
      })
    })
    this.setAllowedCategories();

    this.editOptionValueModal.show();
  }

  showEditMode(option: CubicleOption, optionValue: CubicleOptionValue, reference: string) {
    this.editOptionValueForm = {name: option.name, value: {}, description: {}, valueId: optionValue.value};
    this.editedSwitchboardReference = reference;
    this.editedOptionName = option.name;
    this.editedOptionValueName = optionValue.value;

    if(optionValue.reference){
      this.editOptionValueForm.bslReference = optionValue.reference.ref;
    }

    this.translationService.getLocalesOfCountryOfKey(optionValue.value.toString()).subscribe(locale => {
      locale.forEach(loc => {
        this.editOptionValueForm.value[loc.language] = loc.translations[0].translation;
      })
    });

    this.translationService.getLocalesOfCountryOfKey(optionValue.reference.description.toString()).subscribe(locale => {
      locale.forEach(loc => {
        this.editOptionValueForm.description[loc.language] = loc.translations[0].translation;
      })
    });

    this.setAllowedCategories();

    this.editOptionValueModal.show();
  }

  hide() {
    this.editOptionValueModal.hide();
  }

  isValidForm(): boolean {
    if(!this.editOptionValueForm.name){
      return false;
    }

    for (let key in this.editOptionValueForm.value) {
      if(!this.editOptionValueForm.value[key]){
        return false;
      }
    }

    return !(this.editOptionValueForm.bslReference == null || this.editOptionValueForm.bslReference.length == 0);
  }

  clickOnSaveOptionValue() {

    let selectedOption;
    let editPayload: EditOptionValuePayload;

    if (this.availableOptions !== undefined && this.availableOptions !== null && this.availableOptions.length > 0) {
      selectedOption= this.availableOptions.find(opt => opt.name === this.editOptionValueForm.name);
      editPayload= {
        name: this.editOptionValueForm.value,
        description: this.editOptionValueForm.description,
        rangeId : this.selectedRange.id,
        reference: selectedOption.reference,
        bslReference: this.editOptionValueForm.bslReference,
        optionNameKey: selectedOption.name};

    } else {
      editPayload= {
        name: this.editOptionValueForm.value,
        description: this.editOptionValueForm.description,
        rangeId : this.selectedRange.id,
        reference: this.editedSwitchboardReference,
        bslReference: this.editOptionValueForm.bslReference,
        optionNameKey: this.editedOptionName,
        optionValueNameKey: this.editedOptionValueName};
    }
    this.saveOptionValue.emit(editPayload);
  }

  clickAddReferenceButton(){
    this.addReferenceModal.show();
  }

  addReference(event: any){
    this.editOptionValueForm.bslReference = event.bslReference.reference;
    this.isLoading = true
    this.optionsService.getOptionDescriptionTranslations(this.selectedRange.id, event.bslReference.reference).subscribe(translations => {
      this.editOptionValueForm.value = translations[0].translations;
      this.editOptionValueForm.description = translations[1].translations;
      this.isLoading = false;
    });
  }

  clickDeleteReferenceButton() {
    this.editOptionValueForm.bslReference = null;
    this.isLoading = true
      this.translationService.getAllLanguagesByCountry(this.user.partnerCountry).subscribe(languages => {
          languages.forEach(lang => {
              this.editOptionValueForm.value[lang] = '';
              this.editOptionValueForm.description[lang] = '';
              this.isLoading = false;
          });
      });
  }

  // function for the ngfor + ngmodel, so it doesn't lose focus
  trackByFn(index, item): number {   return index; }

  private setAllowedCategories () {
      this.allowedReferenceCategoriesForOptions = ['Accessory', 'Accessories', 'Kit'];
  }

}
