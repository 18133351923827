<app-spinner *ngIf="loading" [sizeClass]="'medium'"></app-spinner>
<p-menu #menuGroup [popup]="true" [model]="actionsGroup"></p-menu>
<p-menu #menuOffer [popup]="true" [model]="actionsOffer"></p-menu>

<div *ngIf="!loading">
  <h2 translate>T_ADMIN_PACKAGE_MANAGEMENT_TITLE</h2>
  <div>
    <div class="package-offer-actions">
      <div class="package-offer-select">
        <div>
          <label for="selectGroup" class=" control-label text-left" translate>T_PACKAGE_OFFER_GROUP_LABEL</label>
          <button class="fa fa-pencil edit-translation-button" [tooltip]="getLabelTooltip()" (click)="goToTranslationPage('T_PACKAGE_OFFER_GROUP_LABEL')"></button>
          <select id="selectGroup" class="select" (change)="selectPackageGroup($event)">
            <option [selected]="!selectedGroupId" translate>T_ADMIN_PACKAGE_MANAGEMENT_CHOOSE_GROUP</option>
            <ng-container *ngFor="let group of packageGroups">
              <option [selected]="selectedGroupId === group.id" [value]="group.id" translate>{{group.name}}</option>
            </ng-container>
          </select>
          <button type="button" pButton icon="pi pi-bars" class="action-menu-open-button" (click)="menuGroup.toggle($event)"></button>
        </div>
      </div>
      <div class="package-offer-select" [class.package-offer-actions-hidden]="!selectedGroupId">
        <div>
          <label for="selectOffer" class=" control-label text-left" [tooltip]="getLabelTooltip()" (click)="goToTranslationPage('T_PACKAGE_OFFER_LABEL')" translate>T_PACKAGE_OFFER_LABEL</label>
          <button class="fa fa-pencil edit-translation-button" [tooltip]="getLabelTooltip()" (click)="goToTranslationPage('T_PACKAGE_OFFER_LABEL')"></button>
          <select id="selectOffer" class="select" (change)="selectPackageOffer($event)">
            <option [selected]="!packageOffer" translate>T_ADMIN_PACKAGE_MANAGEMENT_CHOOSE_OFFER</option>
            <ng-container *ngFor="let offer of packageOffersOfGroup">
              <option [selected]="packageOffer && packageOffer.id === offer.id" [value]="offer.id" translate>{{offer.name}}</option>
            </ng-container>
          </select>
          <button type="button" pButton icon="pi pi-bars" class="action-menu-open-button" (click)="menuOffer.toggle($event)"></button>
        </div>
      </div>
      <div [class.package-offer-actions-hidden]="!packageOffer">

        <button type="button" class="btn btn-default-gray-background" (click)="imageInput.click()" translate>
          <em class="pi pi-download">&nbsp;</em>
          T_UPLOAD_IMAGE
          <input #imageInput type="file" (change)="imageUpload($event)" accept=".jpg, .jpeg" style="display:none" />
        </button>
        <app-element-tile *ngIf="packageOffer && packageOffer.image"
                          [pictureFileId]="packageOffer.imageFileId"
                          [readOnly]="true"
                          [clickable]="false">
        </app-element-tile>

      </div>
      <div [class.package-offer-actions-hidden]="!packageOffer">
        <button type="button" class="btn btn-default-gray-background" (click)="fileInput.click()" translate>
          <em class="pi pi-download">&nbsp;</em>
          T_PRICE_IMPORT
          <input #fileInput type="file" (change)="fileUploader($event)" accept=".xlsx, .xls" style="display:none" />
        </button>
        <button type="button" class="btn btn-default-gray-background btn-export" (click)="fileExport()" translate>
            <em class="pi pi-upload">&nbsp;</em>
            T_PRICE_EXPORT
        </button>
        <button class="btn btn-default-gray-background" (click)="showDeleteOfferContentModal()" translate>T_PACKAGE_MANAGEMENT_DELETE_ALL_PACKAGES_BUTTON</button>

        <div class="package-info">
          <em class="fa fa-info-circle">&nbsp;</em>
          <label translate>T_PACKAGE_CREATE_INFO</label>
        </div>
        <div class="package-info">
          <em class="fa fa-info-circle">&nbsp;</em>
          <label translate>T_PACKAGE_UPDATE_INFO</label>
        </div>
        <div class="package-info">
          <em class="fa fa-info-circle">&nbsp;</em>
          <label translate>T_PACKAGE_DELETE_INFO</label>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="packageOffer" class="package-list">
    <p-table [value]="packages" [paginator]="true" [rows]="10" [showCurrentPageReport]="true">
      <ng-template pTemplate="header">
        <tr>
          <th scope="col">
            Id
            <p-columnFilter type="text" field="id" matchMode="contains"></p-columnFilter>
          </th>
          <th scope="col" translate>
            T_PACKAGE_CHARACTERISTICS_LIST_TITLE
            <p-columnFilter type="text" field="characteristicsAsString" matchMode="contains"></p-columnFilter>
          </th>
          <th scope="col" translate>
            T_PACKAGE_SWITCHBOARD_LIST_TITLE
            <p-columnFilter type="text" field="referencesAsString" matchMode="contains"></p-columnFilter>
          </th>
          <th scope="col" translate>
            T_PACKAGE_ACCESSORY_REFERENCE_LIST_TITLE
            <p-columnFilter type="text" field="accessoryReferencesAsString" matchMode="contains"></p-columnFilter>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-pack>
        <tr>
          <td>
            {{pack.id}}
          </td>
          <td>
            <ul>
              <li *ngFor="let characteristic of characteristicsOfferPackage">
                {{characteristic}} : {{pack.characteristics[characteristic]}}
              </li>
            </ul>
          </td>
          <td>
            <ul>
              <li *ngFor="let switchboardComponent of pack.switchboardComponents">
                {{switchboardComponent.reference.ref}}
              </li>
            </ul>
          </td>
          <td>
            <ul *ngFor="let entry of pack.accessoriesReferencesMap | keyvalue">
              <li *ngFor="let accessoryReference of entry.value">
                {{accessoryReference.ref}}
              </li>
            </ul>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>


<app-odm-modal-name #addGroupModal
                             [isAddedModal] = "true"
                             (onConfirmButton)="addGroup($event)" >
</app-odm-modal-name>

<app-common-modal #deleteGroupModal
                  [title]="'T_PACKAGE_MANAGEMENT_DELETE_GROUP_MODAL_TITLE' | translate"
                  [name]="'T_PACKAGE_MANAGEMENT_DELETE_GROUP_MODAL_TITLE'"
                  [description]="'T_PACKAGE_MANAGEMENT_DELETE_GROUP_MODAL_DESCRIPTION' | translate"
                  [acceptInnerHtml] = "true" (onClickYesButton)="deleteGroup()">
</app-common-modal>

<app-odm-modal-name #addOfferModal
                    [isAddedModal] = "true"
                    (onConfirmButton)="addOffer($event)" >
</app-odm-modal-name>

<app-common-modal #deleteOfferModal
                  [title]="'T_PACKAGE_MANAGEMENT_DELETE_OFFER_MODAL_TITLE' | translate"
                  [name]="'T_PACKAGE_MANAGEMENT_DELETE_OFFER_MODAL_TITLE'"
                  [description]="'T_PACKAGE_MANAGEMENT_DELETE_OFFER_MODAL_DESCRIPTION' | translate"
                  [acceptInnerHtml] = "true" (onClickYesButton)="deleteOffer()">
</app-common-modal>

<app-common-modal #deleteOfferContentModal
                  [title]="'T_PACKAGE_MANAGEMENT_DELETE_OFFER_CONTENT_MODAL_TITLE' | translate"
                  [name]="'T_PACKAGE_MANAGEMENT_DELETE_OFFER_CONTENT_MODAL_TITLE'"
                  [description]="'T_PACKAGE_MANAGEMENT_DELETE_OFFER_CONTENT_MODAL_DESCRIPTION' | translate"
                  [acceptInnerHtml] = "true" (onClickYesButton)="deleteOfferContent()">
</app-common-modal>

<app-order-management-modal #packageOfferGroupsReorderModal (orderSaved)="onPackageOfferGroupsOrderSaved($event)"></app-order-management-modal>
<app-order-management-modal #packageOffersReorderModal (orderSaved)="onPackageOffersOrderSaved($event)"></app-order-management-modal>

<app-odm-edit-package-offer-modal #editOfferModal
                            [selectedAccess]="selectedAccess"
                           (changeRestrictedAccess)="onChangeRestrictedAccess($event)">
</app-odm-edit-package-offer-modal>
