<p-card class="option">
  <div class="option-title">
    <h5 translate>{{option.name}}</h5>
    <button class="action-button se-icon" (click)="onClickEditOptionButton(option)">action_editor</button>
    <button class="action-button se-icon" (click)="onClickCopyOptionButton(option)">action_duplicate</button>
    <button class="action-button se-icon" (click)="onClickDeleteOptionButton(option)">action_delete</button>
    <button (click)="onClickOpenOptionButton()" class="se-icon option-open-button">{{isExpanded ? "arrow2_down" : "arrow2_right"}}</button>
  </div>

  <div *ngIf="isExpanded">
    <p *ngIf="showOnPopUpStatus">
      <span translate>T_ADMIN_OFFER_DATA_MANAGEMENT_OPTION_ON_POP_UP</span><span>: </span><span *ngIf="option.onPopUp" translate>T_YES</span><span *ngIf="!option.onPopUp" translate>T_NO</span>
    </p>

    <app-odm-manage-option-values
    [values]="option.values"
    (clickEditOptionValueButton)="onClickEditOptionValueButton(option, $event)"
    (clickDeleteOptionValueButton)="onClickDeleteOptionValueButton(option, $event)">
    </app-odm-manage-option-values>
  </div>
</p-card>

