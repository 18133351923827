import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CubicleOption, CubicleOptionValue} from '../../../../shared/model/cubicleOption-model';
import {AccessoryCharacteristics} from '../../../../shared/model/accessory.model';
import {CharacteristicValue} from '../../../../characteristics/shared/characteristics.model';

@Component({
  selector: 'app-odm-manage-option',
  templateUrl: './odm-manage-option.component.html',
  styleUrls: ['./odm-manage-option.component.less']
})
export class OdmManageOptionComponent {

  @Input()
  option: CubicleOption;

  @Input()
  showOnPopUpStatus: boolean = true;

  @Input()
  isExpanded = false;

  @Output()
  clickEditOptionButton: EventEmitter<CubicleOption> = new EventEmitter<CubicleOption>();
  @Output()
  clickCopyOptionButton: EventEmitter<CubicleOption> = new EventEmitter<CubicleOption>();
  @Output()
  clickDeleteOptionButton: EventEmitter<CubicleOption> = new EventEmitter<CubicleOption>();

  @Output()
  clickEditOptionValueButton: EventEmitter<{option: CubicleOption, optionValue: CubicleOptionValue}> = new EventEmitter<{option: CubicleOption, optionValue: CubicleOptionValue}>();

  @Output()
  clickDeleteOptionValueButton: EventEmitter<{option: CubicleOption, optionValue: CubicleOptionValue}> = new EventEmitter<{option: CubicleOption, optionValue: CubicleOptionValue}>();

  constructor() { }


  onClickOpenOptionButton(){
    this.isExpanded = !this.isExpanded;
  }

  onClickEditOptionButton(option: CubicleOption){
    this.clickEditOptionButton.emit(option);
  }

  onClickCopyOptionButton(option: CubicleOption){
    this.clickCopyOptionButton.emit(option);
  }

  onClickDeleteOptionButton(option: CubicleOption){
    this.clickDeleteOptionButton.emit(option);
  }

  onClickEditOptionValueButton(option: CubicleOption, optionValue: CubicleOptionValue) {
    this.clickEditOptionValueButton.emit({option, optionValue});
  }

  onClickDeleteOptionValueButton(option: CubicleOption, optionValue: CubicleOptionValue) {
    this.clickDeleteOptionValueButton.emit({option, optionValue});
  }


}
