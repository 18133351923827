import {PartnerDiscount, Range, RangeStatus, RestrictedOrderingType} from './range-model';
import {SwitchboardLimit} from './switchboardLimit-model';
import {Packing} from './packingSelected-model';
import {RangeType} from './range-type';
import {RangeBslDocument} from '../../admin/shared/bsl-reference/offer-data-management-model';
import {Service} from '../../admin/shared/services/services.model';

export class SimplifiedRange {
  id = null;
  nameKey: string;
  shortDescriptionKey: string;
  imageKey: string;
  switchboardLimit: SwitchboardLimit;
  functionnalUnitsTransformerCompatible?: Array<any>;
  refs: Array<string> = null;
  rangeType: RangeType;
  filters: Array<string> = null;
  mainCharacteristics: Array<string> = [];
  electricalCharacteristicsFilters: Array<string> = [];
  mto: boolean;
  documents?: Array<RangeBslDocument>;
  version: string;
  priceManagement: PriceManagement;
  deliveryPolicy: DeliveryPolicy;
  rangePicture: RangePicture;
  optionalPacking?: Array<Packing> = null;
  visibleComponentsFilters: string[];
  hideCharacteristicScreen: boolean;
  digiQCountry: boolean;
  cardSortCriterion: string;
  useTransformerSelector: boolean;
  emailOrdering: string;
  restrictedOrderingType: RestrictedOrderingType;
  status?: RangeStatus;
  services: Service[] = [];
  partnerDiscounts: PartnerDiscount[] = [];
  accessoriesInformation: string;

  constructor(range: Range) {
    this.id = range.id;
    this.nameKey = range.nameKey;
    this.shortDescriptionKey = range.shortDescriptionKey;
    this.imageKey = range.imageKey;
    this.switchboardLimit = range.switchboardLimit;
    this.functionnalUnitsTransformerCompatible = range.functionnalUnitsTransformerCompatible;
    this.refs = range.refs;
    this.rangeType = range.rangeType;
    this.mainCharacteristics = range.mainCharacteristics;
    this.electricalCharacteristicsFilters = range.electricalCharacteristicsFilters;
    this.hideCharacteristicScreen = range.hideCharacteristicScreen;
    this.mto = range.mto;
    this.priceManagement = range.priceManagement;
    this.deliveryPolicy = range.deliveryPolicy;
    this.rangePicture = range.rangePicture;
    this.optionalPacking = range.optionalPacking;
    this.visibleComponentsFilters = range.visibleComponentsFilters;
    this.digiQCountry = range.digiQCountry;
    this.cardSortCriterion = range.cardSortCriterion;
    this.useTransformerSelector = range.useTransformerSelector;
    this.emailOrdering = range.emailOrdering;
    this.restrictedOrderingType = range.restrictedOrderingType;
    this.services = range.services;
    this.partnerDiscounts = range.partnerDiscounts;
    this.status = range.status;
    this.accessoriesInformation = range.accessoriesInformation;
  }

}

export class PriceManagement {
  validityPeriod: number;
  discountAllowed: number;
}

export class DeliveryPolicy {
  minimumTime: number;
  additionalTime: number;
}

export class RangePicture {
  url: string;
  date: number;
  imageFileId: string;
}
