<app-spinner *ngIf="loading" [sizeClass]="'large'"><span class="waiting-message" translate>T_OFFER_DATA_MANAGEMENT_LOADING_TIME</span></app-spinner>
<div *ngIf="!loading">
  <!-- NO RANGE FOUND MESSAGE -->
  <div *ngIf="initialized && ranges.length === 0" class="text-center">
    <p class="lead" translate>T_OFFER_DATA_MANAGEMENT_NO_RANGE_FOUND</p>
  </div>

  <!-- LIST OF RANGES -->
  <div *ngIf="rangesObservable">
    <h2 class="offer" translate>{{titleHeader}}
      <span *ngIf="getTitleTooltip()" class="se-icons information-tooltip" [tooltip]="getTitleTooltip()"> information_circle </span>
    </h2>
    <div *ngIf="showImportExportButtons" class="button-container">
      <button type="button" class="btn btn-default-gray-background" (click)="fileInput.click()" translate>
        <em class="pi pi-download">&nbsp;</em>
        T_PRICE_IMPORT
        <input #fileInput type="file" (change)="rangeImport($event)" accept=".xlsx, .xls" style="display:none" />
      </button>
      <button type="button" class="btn btn-default-gray-background" (click)="rangeExport('template')" translate>
        <em class="pi pi-upload">&nbsp;</em>
        T_PRICE_EXPORT
      </button>
      <span class="se-icons information-tooltip information-icon" [tooltip]="getTranslation('T_PRICE_EXPORT_TOOLTIP')"> information_circle </span>
      <app-spinner *ngIf="loadingImportExport" [vCenter]="false" [sizeClass]="'extra-small'"></app-spinner>
    </div>
    <div class="card-list" cdkDropListGroup>
      <div cdkDropList cdkDropListOrientation="horizontal" [cdkDropListData]="i" *ngFor="let range of rangesObservable; let i = index;">
        <div class="card-container" cdkDrag [cdkDragDisabled]="!orderList" [cdkDragData]="i" (cdkDragDropped)="drop($event)">
          <div class="card-range">
            <app-element-tile *ngIf="range.status !== 'NO_REFERENCE'"
                              [customClass]="'offer-data-management'"
                              [status]="range.status"
                              [readOnly]="false"
                              [name]="range.nameKey"
                              [pictureUrl]="range.rangePicture?.url"
                              [picturesAssetsPath]="range.imageKey"
                              [pictureFileId]="range.rangePicture?.imageFileId"
                              [clickable]="false"
                              [actionsMenu]="getActionsMenu(range)"
                              (clickOnActionMenu)="clickedOnActionMenu($event, range)">
            </app-element-tile>

            <app-element-tile *ngIf="range.status === 'NO_REFERENCE'"
                              [customClass]="'offer-data-management'"
                              [status]="range.status"
                              [readOnly]="true"
                              [name]="range.nameKey"
                              [pictureUrl]="range.rangePicture?.url"
                              [picturesAssetsPath]="range.imageKey"
                              [pictureFileId]="range.rangePicture?.imageFileId"
                              [optionalTooltip]="'T_OFFER_DATA_MANAGEMENT_NO_REFERENCE_RANGE'">
            </app-element-tile>
          </div>
        </div>
      </div>
    </div>

  </div>

</div>

<app-common-modal #decommissionRangeModal [title]="'T_OFFER_DATA_MANAGEMENT_DECOMMISSION_RANGE_MODAL_TITLE' | translate" [name]="'T_OFFER_DATA_MANAGEMENT_DECOMMISSION_RANGE_MODAL_TITLE'"
                  [description]="'T_OFFER_DATA_MANAGEMENT_DECOMMISSION_RANGE_MODAL_MESSAGE' | translate" (onClickYesButton)="decommissionRange(rangeToAction)" [size]="'modal-default'">
</app-common-modal>

<app-common-modal #transformToImportedRangeModal [title]="'T_OFFER_DATA_MANAGEMENT_TRANSFORM_TO_IMPORTED_RANGE' | translate" [name]="'T_OFFER_DATA_MANAGEMENT_TRANSFORM_TO_IMPORTED_RANGE'"
                  [description]="'T_OFFER_DATA_MANAGEMENT_TRANSFORM_TO_IMPORTED_RANGE_MODAL_MESSAGE' | translate" (onClickYesButton)="onTransformRangeImported(rangeToAction)" [size]="'modal-default'">
</app-common-modal>

<app-common-modal #publishRangeModal [title]="'T_OFFER_DATA_MANAGEMENT_RANGE_PUBLICATION' | translate" [name]="'T_OFFER_DATA_MANAGEMENT_RANGE_PUBLICATION'"
                  [description]="'T_OFFER_DATA_MANAGEMENT_PUBLISH_OFFER_CONFIRM' | translate" (onClickYesButton)="publishRange(rangeToAction)" [size]="'modal-default'">
</app-common-modal>
<app-odm-range-management-modal #rangeManagementModal (saveRangeInfo)="onSaveRangeInfo($event)"></app-odm-range-management-modal>
<app-odm-transform-to-selector-range-modal #transformToSelectorRangeModal (transformRangeInfo)="onTransformRangeSelector($event)"></app-odm-transform-to-selector-range-modal>
