<app-common-modal #transformToSelectorRangeModal [name]="'transformToSelectorRangeModal'" [title]=" " [twoButtonsDialogs]="true"
                  [withFooter]="false">
  <h4 modal-title class="modal-title" translate>T_OFFER_DATA_MANAGEMENT_RANGE_INFORMATION</h4>
  <div modal-body  class="modal-body">
    <h5 translate>T_OFFER_DATA_MANAGEMENT_VISIBLE_FILTERS</h5>
    <div>
      <div *ngFor="let availableCharacteristic of availableCharacteristics">
        <div>
          <label class="custom-checkbox-container">
            <input type="checkbox"
                   class="checkbox"
                   (click)="onCharacteristicChange(availableCharacteristic)"
                   [checked]="isSelected(availableCharacteristic)"
                   name="onCharacteristicChange">
            <span class="checkmark"></span>
            <span translate>{{availableCharacteristic}}</span>
          </label>
        </div>
      </div>

      <app-spinner *ngIf="loading" [sizeClass]="'small'" class="combination-spinner"></app-spinner>
      <span *ngIf="!loading && !isValidForm()" class="combination-disclaimer" translate>T_OFFER_DATA_MANAGEMENT_COMBINATION_ERROR</span>
    </div>
    <div class="modal-footer">
      <button class="btn btn-default-white-background" (click)="hide()" translate>T_BUTTON_CANCEL</button>
      <button class="btn btn-default-white-background" [disabled]="!isValidForm()"
              (click)="clickOnSaveRangeButton(); hide()" translate>T_OFFER_DATA_MANAGEMENT_SAVE_RANGE_INFO_BUTTON
      </button>
    </div>
  </div>


</app-common-modal>

