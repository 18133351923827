<app-common-modal #editOptionValueModal [name]="'editAccessoryValueModal'" [title]=" " [twoButtonsDialogs]="true" [withFooter]="false">

  <h4 modal-title class="modal-title" translate>T_OFFER_DATA_MANAGEMENT_OPTION_VALUE_INFORMATION</h4>
  <div modal-body>
    <div *ngIf="availableOptions">
      <h5 translate>T_OPTION_SELECT</h5>
      <p-dropdown
        class="edit-option-value-dropdown"
        [options]="availableOptions"
        [(ngModel)]="editOptionValueForm.name"
        optionLabel="nameTranslated"
        optionValue="name"
        [autoDisplayFirst]="false">
      </p-dropdown>
    </div>

    <h5 translate>T_OPTION_REFERENCE</h5>
    <p *ngIf="!editOptionValueForm.bslReference">
      <span translate>T_OPTION_NO_REFERENCE</span>
    </p>
    <p *ngIf="editOptionValueForm.bslReference">
      <span>{{editOptionValueForm.bslReference}}</span>
      <button class="action-button se-icon" (click)="clickDeleteReferenceButton()">action_delete</button>
    </p>
    <button *ngIf="!editOptionValueForm.bslReference" class="btn btn-smart" (click)="clickAddReferenceButton()" translate>
      <span class="se-icons">action_add_stroke</span>T_OPTION_REFERENCE_ADD_BUTTON
    </button>

    <h5 translate>T_OPTION_NAME</h5>
    <app-spinner *ngIf="isLoading" class="spinner" [sizeClass]="'medium'"></app-spinner>
    <div *ngIf="!isLoading">
      <div class="edit-option-value-name" *ngFor="let item of editOptionValueForm.value | keyvalue; trackBy: trackByFn">
        <span class="edit-option-value-name-label">{{item.key}}:</span>
        <input class="edit-option-value-name-input" type="text" [(ngModel)]="editOptionValueForm.value[item.key]"/>
      </div>

    <h5 translate>T_OPTION_DESCRIPTION</h5>
    <div class="edit-option-value-description" *ngFor="let item of editOptionValueForm.description | keyvalue; trackBy: trackByFn">
      <span class="edit-option-value-description-label">{{item.key}}:</span>
      <input class="edit-option-value-description-input" type="text" [(ngModel)]="editOptionValueForm.description[item.key]" />
    </div>
    </div>


    <div class="edit-option-value-modal-footer">
      <button class="btn btn-default-white-background" (click)="hide()" translate>T_BUTTON_CANCEL</button>
      <button class="btn btn-default-white-background" [disabled]="!isValidForm()" (click)="clickOnSaveOptionValue(); hide()" translate>T_OFFER_DATA_MANAGEMENT_SAVE_OPTION_BUTTON</button>
    </div>
  </div>


</app-common-modal>

<!--Modal add reference-->
<app-odm-add-modal #addReferenceModal
                                     (onAddReferenceButton)="addReference($event)"
                                     [selectedRange]="selectedRange"
                                     [allowedReferenceCategories]="allowedReferenceCategoriesForOptions"
>
</app-odm-add-modal>
